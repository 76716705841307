import { useParams, useLocation, useNavigate } from 'react-router-dom';
import React, { useState, useEffect, CSSProperties, useRef, useCallback } from 'react';
import SignatureCanvas from 'react-signature-canvas'
// services
import bundeeRestApi from '../../services/BundeeRestApi';
import fluxRestApi from '../../services/FluxRestApi';
// firebase
import { ref, onValue, off, get } from '@firebase/database';
import { rtdbFleetCars, rtdbStations } from '../../firebase/firebase';
// components
import Loader from '../Loader';
import ModalComponentTripDetails from '../../components/modal/ModalComponentTripDetails';
import ContactForm from '../ContactForm';
import VerifyLicenseModal from '../license/VerifyLicenseModal';
import TripChatModal from '../chat/TripChatModal';
import InsuranceModal from '../insurance/InsuranceModal';
import RentalAgreement from './tripDetailsComponents/RentalAgreement';
import DatePickerExtension from './tripDetailsComponents/DatePickerExtension';
import CardForm from './tripDetailsComponents/CardForm';
// assets
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import stylesCarDetails from '../../styles/CarDetails.module.css'
import authStyles from '../../styles/authStyles';
import SplashScreen from '../../images/banners/banner-black-car.webp';
import placeHolder from '../../images/place-holder.png';
import NoImage from '../../images/no-image-available.png';
import stylesModal from '../../styles/carDetailsModal.module.css';
// hooks
import { useIsMobile, useAnimateOnDesktop } from '../../hooks/useIsMobile';
// others
import moment from 'moment-timezone';
import { useStripe, useElements } from '@stripe/react-stripe-js';
// context
import { useAuth } from '../../Context/AuthContext';
// utils
import showToast from '../../utils/toastHelpers';
// dates lib
import AgreementsForm from '../AgreementsForm';

interface TripDetailsProps {
    tripId?: string;
}

const TripDetails: React.FC<TripDetailsProps> = () => {
    const navigate = useNavigate();
    const { tripId } = useParams();
    const location = useLocation();
    // context
    const { contactData, updatePartialProperties } = useAuth();
    // hooks
    const isMobile = useIsMobile();
    const shouldAnimate = useAnimateOnDesktop(isMobile);
    // states
    const [addressLoading, setAddressLoading] = useState<boolean>(false);
    const [stationDetails, setStationDetails] = useState<any>(null);
    const [details, setDetails] = useState<any>(location.state?.details || null);
    const [tripObject, setTripObject] = useState<any>(location.state?.details?.tripObject || null);
    const [carObject, setCarObject] = useState<any>(location.state?.details?.carObject);
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [splashScreen, setSplashScreen] = useState<string>(SplashScreen);
    const [formValues, setFormValues] = useState({
        dateFrom: "dateFrom",
        dateTo: "dateTo",
        timeFrom: "minHour",
        timeTo: 'minHour',
    });
    const [currentContactData, setCurrentContactData] = useState<any>(null);
    const [loadingExtension, setLoadingExtension] = useState<boolean>(false);
    const [modalConfirmExtension, setModalConfirmExtension] = useState<boolean>(false);
    const [totalAmount, setTotalAmount] = useState<number>(0);
    const [totalAmountNew, setTotalAmountNew] = useState<number>(0);
    const [confirmExtension, setConfirmExtension] = useState<boolean>(false);
    // pdf states
    const [rentersData, setRentersData] = useState<any>(null);
    const [rentalLogistics, setRentalLogistics] = useState<any>(null);
    const [vehicleAndProviderInformation, setVehicleAndProviderInformation] = useState<any>(null);
    const [showRentAgreement, setShowRentAgreement] = useState<boolean>(false);
    const [agreementNumber, setAgreementNumber] = useState<string>('0');
    const [urlPDF, setUrlPDF] = useState<string>('');
    const [savedFile, setSavedFile] = useState<File | null>(null);
    const [agreementAccepted, setAgreementAccepted] = useState<boolean>(false);
    // account status
    const [messageErrorAccountStatus, setMessageErrorAccountStatus] = useState<any>(null);
    //
    const [isUserAttachedToTrip, setIsUserAttachedToTrip] = useState<boolean>(false);
    // chat
    const [openChat, setOpenChat] = useState<boolean>(false);
    // component Modal contact form
    const [loadingModalComponent, setLoadingModalComponent] = useState<boolean>(false);
    const [showModalComponent, setShowModalComponent] = useState<boolean>(false);
    // component Modal Agreements form
    const [showAgreementsForm, setShowAgreementsForm] = useState<boolean>(false);
    // Modal License Verification
    const [showModalLicenseVerification, setShowModalLicenseVerification] = useState<boolean>(false);
    // Insurance Modal
    const [showInsuranceModal, setShowInsuranceModal] = useState<boolean>(false);
    // stripe
    const [selectedCard, setSelectedCard] = useState<string>('');
    // bundee user data
    const [bundeeUserData, setBundeeUserData] = useState<any>(null);
    // Modal verify phone
    const [showModalVerifyPhone, setShowModalVerifyPhone] = useState<boolean>(false);


    // styles
    const styles: { [key: string]: CSSProperties } = {
        errorContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100vw',
            height: '100vh',
            backgroundColor: '#f8f8f8',
            textAlign: 'center',
            padding: '20px'
        },
        refreshButton: {
            marginTop: '20px',
            padding: '10px 20px',
            fontSize: '16px',
            backgroundColor: '#333',
            color: 'white',
            border: 'none',
            borderRadius: '15px',
            cursor: 'pointer',
        },
        carInfo: {
            display: 'flex',
            flexDirection: 'column',
            width: '80%',
            height: 'auto',
            alignItems: 'center',
            marginBottom: '1rem',
        },
        carInfoWrapper: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            height: 'auto',
            backgroundColor: '#D8D8D866',
            padding: '0.5rem',
            marginBottom: '0.5rem',
            borderRadius: '4px',
        },
        // modal verify my phone
        modalVerifyPhone: {
            textAlign: 'center',
            padding: '20px',
        },
        modalVerifyPhoneTitle: {
            marginBottom: '10px',
        },
        modalVerifyPhoneText: {
            marginBottom: '20px',
        },
        modalVerifyPhoneButton: {
            marginRight: '10px',
            padding: '10px 20px',
            fontSize: '16px',
            backgroundColor:  '#C7F100',
            color: 'black',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
        },
        modalVerifyPhoneLaterButton: {
            padding: '10px 20px',
            fontSize: '16px',
            backgroundColor: '#ccc',
            color: '#333',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
        },
    };

    // functions
    const handleStartTrip = async () => {
        setLoading(true);
        try {
            const validateDates = validateDatesToStartTrip();
            if (!validateDates) {
                setLoading(false);
                return;
            }
            const response = await bundeeRestApi.startTrip(details?.tripObject?.tripid);
            if (response?.data?.errorCode === '0') {
                showToast('Trip started successfully', 'success');
                console.log('Trip started successfully');
                navigate('/trips');
            } else if (response?.data?.errorCode === '1') {
                showToast(String(response?.data?.errorMessage) || 'Failed to start trip', 'error');
            } else {
                showToast('Failed to start trip', 'error');
            }
            setLoading(false);
        } catch (error) {
            console.log('error', error);
            showToast('Failed to start trip', 'error');
            setLoading(false);
        }
    }

    const validateDatesToStartTrip = () => {
        // return true;
        try {
            const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const startDate = moment.utc(details.tripObject.starttime).tz(userTimezone).subtract(1, 'hour');
            const endDate = moment.utc(details.tripObject.endtime).tz(userTimezone).tz(userTimezone);
            const currentDate = moment.tz(userTimezone);

            if (currentDate.isBefore(startDate)) {
                showToast('It is too early to start the trip. Trips can be started at least one hour before the reservation time.', 'error');
                return false;
            } else if (currentDate.isAfter(endDate)) {
                showToast('The reservation period has ended. You cannot start the trip now.', 'error');
                return false;
            } else if (currentDate.isBetween(startDate, endDate, undefined, '[]')) {
                return true;
            } else {
                showToast('You can only start the trip within the reservation period.', 'error');
                return false;
            }
        } catch (error) {
            console.log('error', error);
            showToast('Oops, something went wrong. Please try again.', 'error');
            return false;
        }
    }

    const validateIftheTripIsExpired = () => {
        try {
            const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const endDate = moment.utc(details.tripObject.endtime).tz(userTimezone).tz(userTimezone);
            const currentDate = moment.tz(userTimezone);

            if (currentDate.isAfter(endDate)) {
                showToast('The reservation period has ended. You cannot start the trip now.', 'error');
                return false;
            } else {
                return true;
            }
        } catch (error) {
            console.log('error', error);
            showToast('Oops, something went wrong. Please try again.', 'error');
            return false;
        }
    }

    // pdf functions
    const handleShowRentAgreement = () => {
        // validate dates
        const validateDates = validateIftheTripIsExpired();
        if (!validateDates) {
            return;
        }
        // before to set we need to ensure the data is ready to display
        if (rentersData && rentalLogistics && vehicleAndProviderInformation && agreementNumber) {
            setShowRentAgreement(true);
            return;
        }
    }

    // trip chat
    const closeTripChatHandler = () => setOpenChat(false);
    const handleTripChat = () => {
        setOpenChat(true);
    };

    // contact modal to update agreements and contact data
    const closeModalComponent = () => {
        // check contactData see if the user update or not in order to update currentContactData
        if (contactData?.contact?.city !== currentContactData?.contact?.city || contactData?.contact?.contact_privacyPolicy !== currentContactData?.contact?.contact_privacyPolicy || contactData?.contact?.contact_agreeTerms !== currentContactData?.contact?.contact_agreeTerms) {
            setCurrentContactData(contactData);
        };
        setShowModalComponent(false);
        setShowAgreementsForm(false);
    };

    // modal to verify the license
    const closeModalLicenseVerification = () => {
        if (contactData?.contact?.idscan_request_id !== currentContactData?.contact?.idscan_request_id || contactData?.contact?.idscan_status !== currentContactData?.contact?.idscan_status) {
            setCurrentContactData(contactData);
        }
        setShowModalLicenseVerification(false);
    };

    // close insurance Modal
    const closeInsuranceModal = () => {
        setShowInsuranceModal(false);
    };

    const openModalContactData = () => {
        setShowModalComponent(true);
        setShowAgreementsForm(false);
    };

    const openModalAgreements = () => {
        setShowModalComponent(true);
        setShowAgreementsForm(true);
    };

    const openModalLicenseVerification = () => {
        setShowModalLicenseVerification(true);
    };

    const handleIdontHaveInsurance = async () => {
        try {
            if (currentContactData?.insurance_status === "no_insurance") {
                showToast("Insurance status is already set to 'I don't have insurance'", "info");
                closeInsuranceModal();
                return;
            }
            setLoading(true);
            const body = {
                insurance_status: "no_insurance",
            };
            const response = await updatePartialProperties(contactData, body);
            if (response?.success) {
                setLoading(false);
                showToast("Insurance status updated successfully", "success");
                closeInsuranceModal();
            } else {
                throw new Error("Error updating insurance status");
            }
        } catch (error) {
            setLoading(false);
            console.error("Error creating user in measure one:", error);
            showToast("There was an error, please try again", "error");
        }
    };

    const closeModalPhoneVerification = () => {
        setShowModalVerifyPhone(false);
    };

    const handleVerifyPhone = async () => {
        setShowModalVerifyPhone(false);
        navigate('/verify-phone');
    }


    // effects

    // loading address
    useEffect(() => {
        if (details?.carObject?.places_id && !stationDetails) {
            console.log('runs-find-station-details');
            setAddressLoading(true);
            const stationDataRef = ref(rtdbStations, '/features');
            get(stationDataRef).then((snapshot) => {
                const stationData = snapshot.val();
                if (Array.isArray(stationData)) {
                    const filteredStations = stationData.filter((station: any) => (station.properties.places_id === details?.carObject?.places_id));
                    // console.log('filteredStations', filteredStations);
                    setStationDetails(filteredStations[0] || {});
                    setAddressLoading(false);
                } else {
                    console.error('stationData is not an array');
                    setStationDetails({})
                    setAddressLoading(false);
                }
            }).catch((error) => {
                console.log('useApiStations', error);
                setStationDetails({});
                setAddressLoading(false);
            });

            return () => {
                off(stationDataRef);
            };
        }
    }, [details?.carObject?.places_id, stationDetails]);

    // loading effect
    useEffect(() => {
        if (contactData === null) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [contactData]);

    // if the tripObject is null, fetch the trip details
    useEffect(() => {
        const fetchTripDetails = async () => {
            setLoading(true);
            try {
                const response = await bundeeRestApi.getAllTripDetailsFromValue('tripid', tripId, '', '');
                if (response?.data?.errorCode === '0') {
                    if (response?.data?.activetripresponse?.length > 0) {
                        console.log('tripObject useEffect ');
                        setTripObject(response?.data?.activetripresponse[0]);
                        setLoading(false);
                    } else {
                        console.error("Failed to fetch trip details");
                        setTripObject({});
                        setLoading(false);
                    }
                } else {
                    console.error("Failed to fetch trip details");
                    setTripObject({});
                    setLoading(false);
                }
            } catch (error) {
                console.error("Failed to fetch trip details", error);
                setTripObject({});
                setLoading(false);
            }

        };
        if (tripId && !tripObject && currentContactData) {
            fetchTripDetails();
        }
    }, [tripId, tripObject, currentContactData]);

    // if the carObject is null, fetch the car details
    useEffect(() => {
        if (tripObject?.vehicleId && !carObject && currentContactData) {
            console.log('carObject effect');
            setLoading(true);
            const carDataRef = ref(rtdbFleetCars, '/');

            onValue(carDataRef, (carDataSnapshot) => {
                const carData = carDataSnapshot.val();
                if (Array.isArray(carData)) {
                    const filteredCarsByVehicleId = carData.filter((car: any) => (car.vehicleid === tripObject.vehicleId));
                    console.log('filteredCarsByVehicleId effect');
                    setCarObject(filteredCarsByVehicleId[0] || {});
                } else {
                    console.error('carData is not an array');
                    setCarObject({})
                }
                setLoading(false);
            }, (error) => {
                console.log('useApiStations', error);
                setCarObject({});
                setLoading(false);
            });

            return () => {
                off(carDataRef);
            };
        }
    }, [carObject, tripObject, currentContactData]);

    // set details
    useEffect(() => {
        if (currentContactData) {
            if (tripObject && Object.keys(tripObject).length > 0 && carObject && Object.keys(carObject).length > 0 && !details) {
                console.log('details effect ');
                setDetails({
                    'tripObject': tripObject,
                    'carObject': carObject,
                });
            }
        }
    }, [tripObject, carObject, details, currentContactData]);

    // splash screen effect
    useEffect(() => {
        if (currentContactData) {
            if (carObject?.ID) {
                setSplashScreen(`https://fiat.b-cdn.net/${carObject.ID}.jpeg`);
            } else {
                setSplashScreen(SplashScreen);
            }
        }
    }, [carObject, currentContactData]);

    // set form values
    useEffect(() => {
        if (tripObject?.starttime && tripObject?.endtime && currentContactData) {
            const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            setFormValues({
                dateFrom: moment.utc(tripObject.starttime).tz(userTimezone).format('ddd, MMM DD'),
                dateTo: moment.utc(tripObject.endtime).tz(userTimezone).format('ddd, MMM DD'),
                timeFrom: moment.tz(tripObject.starttime, userTimezone).format('h:mm a'),
                timeTo: moment.tz(tripObject.endtime, userTimezone).format('h:mm a'),
            });
        }
    }, [tripObject, currentContactData]);

    // set current contact data
    useEffect(() => {
        if (contactData && !currentContactData) {
            //console.log('currentContactData effect', contactData);
            setCurrentContactData(contactData);
        }
    }, [contactData, currentContactData]);

    // check if the user signed the rental agreement
    useEffect(() => {
        const fetchStatusIDScan = async () => {
            try {
                if (!currentContactData?.contact?.idscan_request_id) {
                    showToast('Oops! It looks like your license is not validated', 'error');
                    throw new Error('idscan_request_id is null');
                }
                const response = await fluxRestApi.fetchIDScanInfo(currentContactData.contact.idscan_request_id);
                if (!response?.data?.verificationResult) {
                    // if the response is null is a problem with the server IDScan
                    showToast('Oops! An error occurred while trying to get the license information, if persist reload the page or contact support', 'error');
                    throw new Error('An error occurred while trying to get the license information');
                } else if (response?.data?.verificationResult && !response?.data?.verificationResult.document) {
                    // if the document is null is because the IDScan delete the document for policy reasons
                    const data = {
                        idscan_request_id: "",
                        idscan_status: "",
                    };
                    // update the idscan data so the user verify the license again
                    const updateIdscanStatus = await fluxRestApi.updateStatusAccount(currentContactData?.contact?.id, data);
                    if (updateIdscanStatus === null) {
                        showToast('Sorry something went wrong, please try to reload the page or contact support', 'error');
                        throw new Error('updateIdscanStatus failed');
                    }
                    // reload the page to get the new data
                    window.location.reload();
                }

                // Extract data from the response
                const { document } = response?.data?.verificationResult

                // Prepare data to show on the rental agreement
                const RentersData = {
                    driverLicenseNumber: document.id || '-',
                    driverLicenseState: document.state || '-',
                    driverLicenseExpiration: document.expires || '-',
                    driverLicenseBirthDate: document.dob || '-',
                    fullName: `${currentContactData?.contact?.first_name || '-'} ${currentContactData?.contact?.last_name || '-'}`,
                    currentAddress: `${currentContactData?.contact?.line1 || '-'}, ${currentContactData?.contact?.city || '-'}, ${currentContactData?.contact?.state || '-'}, ${currentContactData?.contact?.postal_code || '-'}`,
                    email: currentContactData?.contact?.email || '',
                    phone: currentContactData?.contact?.phone || '',
                };
                setRentersData(RentersData);
            } catch (error) {
                console.error("Error fetching the renter's data - fetchStatusIDScan", error);
                throw new Error('Error fetching the renter data');
            }
        };

        const fetchRentalLogistics = async () => {
            // dates
            const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const startDate = moment.utc(tripObject.starttime).tz(userTimezone).format('MMM D, YYYY h:mm A');
            const endDate = moment.utc(tripObject.endtime).tz(userTimezone).format('MMM D, YYYY h:mm A');
            // location
            const deliveryLocation = details?.carObject?.Station

            if (!startDate || !endDate || !deliveryLocation) {
                showToast('Oops! Something went wrong getting the rental logistics data', 'error');
                console.log('error', 'startDate, endDate, or deliveryLocation is null on rental logistics');
                setError(true);
                return;
            }

            const RentalLogistics = {
                retalDeliveryTime: startDate,
                vehicleReturnTime: endDate,
                vehicleDeliveryLocation: deliveryLocation,
                vehicleReturnLocation: deliveryLocation,
            }
            setRentalLogistics(RentalLogistics);
        }

        const fecthVehicleAndProviderInformation = async () => {
            try {
                const VehicleAndProviderInformation = {
                    vehicleMakeModel: `${details?.carObject?.Make || '-'} ${details?.carObject?.Model || '-'}`,
                    vehicleVin: details?.carObject?.VIN,
                    vehicleOwner: details?.carObject?.Station,
                    fuelOut: '-',
                    mileageOut: '-',
                    fuelIn: '-',
                    mileageIn: '-',
                }
                setVehicleAndProviderInformation(VehicleAndProviderInformation);
                // set the agreement number
                setAgreementNumber(details?.tripObject?.tripid);
                // console.log('VehicleAndProviderInformation', VehicleAndProviderInformation);
            } catch (error) {
                console.error('error to get the vehicle and provider information', error);
                showToast('Oops! Something went wrong.', 'error');
                setError(true);
            }
        }

        const fetchRentAgreement = async () => {
            try {
                setLoading(true);
                const data = {
                    id: currentContactData?.contact?.id,
                    trip_id: details?.tripObject?.tripid,
                }
                const response = await fluxRestApi.getRentalAgreement(data);
                if (response?.data[0]) {
                    setAgreementAccepted(true);
                    setLoading(false);
                } else {
                    setAgreementAccepted(false);
                    await fetchStatusIDScan();
                    await fetchRentalLogistics();
                    await fecthVehicleAndProviderInformation();
                    setLoading(false);
                }
            } catch (error) {
                console.error('error to check if the user signed the rental agreement', error);
                showToast('Oops! Something went wrong.', 'error');
                setError(true);
                setLoading(false);
            }
        }

        if (
            (details?.tripObject?.statusCode === 'REAPP' || details?.tripObject?.statusCode === 'TRSTR')
            && currentContactData?.contact?.id
            && !agreementAccepted
            && details?.carObject?.Station
            && details?.tripObject?.tripid
            && !vehicleAndProviderInformation
            && currentContactData?.contact?.idscan_request_id
            && currentContactData?.contact?.email
        ) {
            fetchRentAgreement();
        }
    }, [details, currentContactData, agreementNumber, agreementAccepted, navigate, tripObject, details?.carObject?.Station, details?.carObject?.Make, details?.carObject?.Model, details?.carObject?.VIN, vehicleAndProviderInformation]);

    // check account status
    useEffect(() => {
        if (currentContactData?.contact?.account_status === 'BLACKLIST') {
            setMessageErrorAccountStatus('Your account is in blacklist, please contact support@fluxev.city or click the button in the top "Chat with us"');
            setError(true);
        }
        else if (currentContactData?.contact?.account_status === 'IN_REVIEW') {
            // setMessageErrorAccountStatus('Your account is in review, please contact support@fluxev.city');
            //setError(true);
        } else if (currentContactData?.contact?.account_status === 'IN_PROCESS') {
            // setMessageErrorAccountStatus('Your account is in process, please contact support@fluxev.city');
            // setError(true);
        }

    }, [currentContactData]);

    // display modal component to update the contact data and agreements
    useEffect(() => {
        if (currentContactData?.contact) {
            if (!currentContactData.contact?.city) {
                showToast('Please complete your profile', 'error');
            } else if (currentContactData.contact?.city && (!currentContactData.contact?.contact_privacyPolicy || !currentContactData.contact?.contact_agreeTerms)) {
                showToast('Please accept the agreements', 'error');
            } else {
                if (!currentContactData?.contact?.idscan_request_id || !currentContactData?.contact?.idscan_status) {
                    showToast('Please validate your license', 'error');
                } else if (currentContactData?.contact?.idscan_status?.toLocaleLowerCase() === 'pending' && currentContactData?.contact?.idscan_request_id) {
                    showToast('Your license is under review, we will notify you when it is ready', 'info');
                }
            }
        }
    }, [currentContactData]);

    // check if the user is attached to the trip
    useEffect(() => {
        if (currentContactData?.contact?.bundee_user_id === String(tripObject?.userid) && !isUserAttachedToTrip) {
            setIsUserAttachedToTrip(true);
        }
    }, [currentContactData, tripObject, isUserAttachedToTrip]);

    // display insurance modal
    useEffect(() => {
        if (!currentContactData?.contact) {
            return;
        }

        const insuranceStatus = currentContactData.contact?.insurance_status;
        if (!showModalVerifyPhone && !insuranceStatus && currentContactData.contact?.city && currentContactData.contact?.contact_privacyPolicy && currentContactData.contact?.contact_agreeTerms && currentContactData?.contact?.idscan_request_id && currentContactData?.contact?.idscan_status) {
            setShowInsuranceModal(true);
        } else {
            setShowInsuranceModal(false);
        }

    }, [currentContactData, showInsuranceModal, showModalLicenseVerification, showModalComponent, showModalVerifyPhone]);

    // update status
    useEffect(() => {
        if (contactData?.contact?.insurance_status !== currentContactData?.contact?.insurance_status) {
            setCurrentContactData(contactData);
        }
    }, [contactData, currentContactData]);

    // get bundee user data
    useEffect(() => {
        const fetchBundeeUserData = async () => {
            try {
                const response = await bundeeRestApi.getUserByEmail(currentContactData?.contact?.email);
                if (response?.data?.userResponse?.iduser) {
                    setBundeeUserData(response?.userResponse);
                    console.log('fetchBundeeUserData');
                } else {
                    throw new Error('error to get the bundee user data');
                }
            } catch (error) {
                console.log('error to get the bundee user data', error);
                showToast('Oops! Something went wrong getting your user data', 'error');
                navigate('/trips');
            }
        }

        if (currentContactData?.contact?.bundee_user_id && !bundeeUserData) {
            fetchBundeeUserData();
        }
    }, [currentContactData, bundeeUserData, navigate]);

    // update verify my phone status on bundee
    useEffect(() => {
        const updatePhoneNumberStatus = async () => {
            console.log('updatePhoneNumberStatus');
            try {
                const data = {
                    "mobilePhone": currentContactData?.contact?.phone,
                    "isPhoneVarified": true,
                    "iduser": currentContactData?.contact?.bundee_user_id,
                }
                const response = await bundeeRestApi.isPhoneVerified(data);
                if (response?.data?.errorCode === '0') {
                    console.log('phone number status updated');
                } else {
                    throw new Error('error to update the phone number status');
                }
            } catch {
                console.log('error to update the phone number status');
            }
        }

        if (bundeeUserData?.iduser && !bundeeUserData?.isPhoneVarified && currentContactData?.contact?.verified_phone === "true" && currentContactData?.contact?.bundee_user_id && currentContactData?.contact?.phone) {
            updatePhoneNumberStatus();
        }
    }, [bundeeUserData, currentContactData]);

    // display modal verify phone
    useEffect(() => {
        if (contactData?.contact && contactData.contact?.verified_phone !== 'true') {
            setShowModalVerifyPhone(true);
        } else {
            setShowModalVerifyPhone(false);
        }
    }, [contactData]);

    const scrollableContentStyles = {
        ...authStyles.commonStyles.scrollableContent,
        paddingTop: isMobile ? '2rem' : authStyles.commonStyles.scrollableContent.paddingTop,
        backgroundColor: '#f0f2f5',
        height: '100%',
    };

    const formPartStyles = {
        ...authStyles.commonStyles.formPart,
        justifyContent: isMobile ? 'flex-start' : authStyles.commonStyles.formPart.justifyContent,
        transform: isMobile ? 'translateX(0%)' : (shouldAnimate ? 'translateX(0%)' : 'translateX(-100%)'),
        paddingBottom: isMobile ? '2rem' : 0,
        backgroundColor: '#f0f2f5',
        height: 'calc(100vh - 77px)',
    };

    return (
        <>
            <Loader loading={loading || loadingExtension} />
            {/* Modal to update the contact data and agreements */}
            <ModalComponentTripDetails isVisible={showModalComponent} onClose={closeModalComponent}>
                <Loader loading={loadingModalComponent} />
                {showAgreementsForm ?
                    <AgreementsForm
                        isMobile={isMobile}
                        animate={shouldAnimate}
                        setLoading={setLoadingModalComponent}
                        renderInAnotherPage={true}
                    />
                    :
                    <ContactForm
                        isMobile={isMobile}
                        animate={shouldAnimate}
                        setLoading={setLoadingModalComponent}
                        renderInAnotherPage={true}
                        setShowAgreementsForm={setShowAgreementsForm}
                    />}
            </ModalComponentTripDetails>
            {/* Modal to verify the license */}
            <ModalComponentTripDetails isVisible={showModalLicenseVerification} onClose={closeModalLicenseVerification}>
                <VerifyLicenseModal contactData={currentContactData} />
            </ModalComponentTripDetails>
            {/* Modal to veridy the Phone */}
            <ModalComponentTripDetails isVisible={showModalVerifyPhone} onClose={closeModalPhoneVerification}>
                <div style={styles.modalVerifyPhone}>
                    <h2 style={styles.modalVerifyPhoneTitle}>Your phone is not verified</h2>
                    <p style={styles.modalVerifyPhoneText}>Please verify your phone</p>
                    <button
                        onClick={handleVerifyPhone}
                        style={styles.modalVerifyPhoneButton}
                    >
                        Verify Phone
                    </button>
                    <button
                        onClick={closeModalPhoneVerification}
                        style={styles.modalVerifyPhoneLaterButton}
                    >
                        Later
                    </button>
                </div>
            </ModalComponentTripDetails>
            <InsuranceModal
                show={showInsuranceModal}
                onClose={closeInsuranceModal}
                onStartProcess={() => navigate('/insurance')}
                onNoInsurance={handleIdontHaveInsurance}
            />
            {!loading && error && messageErrorAccountStatus && (<>  <div style={styles.errorContainer}>
                <h2>{messageErrorAccountStatus}</h2>
                <button onClick={() => navigate('/trips')} style={styles.refreshButton}>Go Trips</button>
            </div></>)}
            {(currentContactData && tripObject && Object.keys(tripObject).length > 0 && carObject && Object.keys(carObject).length > 0 && details && Object.keys(details).length > 0) ? (
                <div style={authStyles.commonStyles.container}>
                    <Modal
                        setLoading={setLoading}
                        setUrlPDF={setUrlPDF}
                        urlPDF={urlPDF}
                        isOpen={showRentAgreement}
                        onClose={() => setShowRentAgreement(false)}
                        rentalAgreementData={() => {
                            return {
                                rentersData,
                                rentalLogistics,
                                vehicleAndProviderInformation,
                                agreementNumber,
                            }
                        }}
                        currentContactData={currentContactData}
                        setSavedFile={setSavedFile}
                        savedFile={savedFile}
                        setAgreementAccepted={setAgreementAccepted}
                    />
                    <ExtendConfirmationModal
                        totalPrice={totalAmount}
                        totalNewPrice={totalAmountNew}
                        isOpen={modalConfirmExtension}
                        onClose={() => setModalConfirmExtension(false)}
                        setConfirmExtension={setConfirmExtension}
                        stripeId={currentContactData?.contact?.stripe_id}
                        selectedCard={selectedCard}
                        setSelectedCard={setSelectedCard}
                    />
                    {openChat &&
                        <TripChatModal
                            isOpen={openChat}
                            tripId={details?.tripObject?.tripid || ''}
                            onClose={closeTripChatHandler}
                            currentContactData={currentContactData}
                            tripDetails={details}
                        />
                    }
                    {/* <SplashImage isMobile={isMobile} animate={shouldAnimate} SplashScreen={splashScreen} /> */}
                    <div style={formPartStyles}>
                        {isUserAttachedToTrip ? (
                            <div style={scrollableContentStyles}>
                                <CarDetails
                                    selectedCard={selectedCard}
                                    openModalContactData={openModalContactData}
                                    openModalAgreements={openModalAgreements}
                                    openModalLicenseVerification={openModalLicenseVerification}
                                    setLoading={setLoading}
                                    stationDetails={stationDetails}
                                    addressLoading={addressLoading}
                                    agreementAccepted={agreementAccepted}
                                    details={details} formValues={formValues}
                                    handleStartTrip={handleStartTrip}
                                    handleShowRentAgreement={handleShowRentAgreement}
                                    rentersData={rentersData}
                                    rentalLogistics={rentalLogistics}
                                    vehicleAndProviderInformation={vehicleAndProviderInformation}
                                    agreementNumber={agreementNumber}
                                    isMobile={isMobile}
                                    splashScreen={splashScreen}
                                    handleTripChat={handleTripChat}
                                    currentContactData={currentContactData}
                                    setLoadingExtension={setLoadingExtension}
                                    setTotalAmount={setTotalAmount}
                                    setTotalAmountNew={setTotalAmountNew}
                                    setModalConfirmExtension={setModalConfirmExtension}
                                    confirmExtension={confirmExtension}
                                    setConfirmExtension={setConfirmExtension}
                                    setTripObject={setTripObject}
                                />
                            </div>

                        ) :
                            (<>
                                <div style={styles.errorContainer}>
                                    <h2>Sorry, You are not attached to this trip</h2>
                                    <p>You need to be attached to this trip to get access the details</p>
                                    <button onClick={() => navigate('/trips')} style={styles.refreshButton}>Go Trips</button>
                                </div>
                            </>)
                        }
                    </div>
                </div>) : (
                <div style={styles.errorContainer}>
                    <h2>Loading...</h2>
                    <p>Please wait a moment</p>
                </div>
            )}
        </>
    );
};

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    rentalAgreementData: () => Object;
    setLoading: (loading: boolean) => void;
    setUrlPDF: (url: string) => void;
    urlPDF: string;
    currentContactData: any;
    setSavedFile: (file: File | null) => void;
    savedFile: File | null;
    setAgreementAccepted: (agreementAccepted: boolean) => void;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, rentalAgreementData, setLoading, setUrlPDF, urlPDF, currentContactData, savedFile, setSavedFile, setAgreementAccepted }) => {
    interface RentalAgreementData {
        rentersData: any;
        rentalLogistics: any;
        vehicleAndProviderInformation: any;
        agreementNumber: string;
    }
    // Create a reference to the rental agreement content
    const agreementContentRef = useRef<HTMLDivElement>(null);
    const { rentersData, rentalLogistics, vehicleAndProviderInformation, agreementNumber } = rentalAgreementData() as RentalAgreementData;
    const isMobile = useIsMobile();
    // styles
    const modalStyles: { [key: string]: CSSProperties } = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 900,
        },
        container: {
            backgroundColor: '#fff',
            borderRadius: '4px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
            width: '80%',
            maxWidth: '80%',
            maxHeight: '80%',
            overflow: 'hidden',
            position: 'relative',
            padding: '16px',
            paddingBottom: '60px',
        },
        containerCloseButton: {
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            paddingTop: '1rem',
            paddingBottom: '1rem',
        },
        closeButton: {
            border: 'none',
            backgroundColor: 'transparent',
            cursor: 'pointer',
            fontSize: '18px',
            fontWeight: 'bold',
        },
        scrollableContent: {
            maxHeight: 'calc(80vh - 50px)', // 80% of the viewport height minus the padding
            overflowY: 'auto',
            overflowX: 'hidden',
            padding: '0 16px',
        },
        carInfo: {
            display: 'flex',
            flexDirection: 'column',
            width: '80%',
            height: 'auto',
            alignItems: 'center',
            marginBottom: '1rem',
        },
        rentalAgreement: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '1rem',
        },
        containerRentalAgreemeNumber: {
            marginTop: '1rem',
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '1rem',
        },
        rentalAgreementNumber: {
            fontWeight: 'bold',
            textDecoration: 'underline',
            paddingLeft: '0.5rem'
        },
        buttonContent: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 'auto',
            marginRight: '1rem',
        }
    };

    if (!isOpen) {
        return null;
    }

    let sigPad: any = null;

    const sendNotificationToSupport = async (title: string, body: string) => {
        try {
            const data = {
                "title": title,
                "body": body,
                "send_sms": true,
                "send_email": true,
            }
            const response = await fluxRestApi.sendNotificationToSupportUsers(data.title, data.body, data.send_sms, data.send_email);
            if (response !== null) {
                console.log("Notification sent successfully to support users");
            }
        } catch (error) {
            console.log("There was an error while trying to send a notification to support users");
        }
    };
    // set isRentalAgreed on bundee
    const setRentalAgreedBundee = async (tripId: string, isRentalAgreed: boolean) => {
        const maxRetries = 3;
        let delay = 500; // Start with a 1/2 second delay

        const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

        for (let attempt = 1; attempt <= maxRetries; attempt++) {
            try {
                const data = {
                    "tripId": tripId.toString(),
                    "isRentalAgreed": isRentalAgreed
                }
                const response = await bundeeRestApi.isRentalAgreed(data);
                if (response?.data?.errorCode === '0') {
                    console.log('setRentalAgreedBundee sent successfully');
                    return; // Exit the function if the request was successful
                } else {
                    throw new Error('Something went wrong with the setRentalAgreedBundee');
                }
            } catch (e) {
                console.log(`Attempt ${attempt} failed. ${attempt < maxRetries ? `Retrying in ${delay}ms...` : ''}`);
                if (attempt < maxRetries) {
                    await sleep(delay);
                    delay *= 2; // Double the delay for exponential backoff
                } else {
                    console.log(e, 'error setRentalAgreedBundee');
                }
            }
        }
    };

    // send a message as a ROBOT
    const sendMessageAsRobot = async (tripId: any, message: string, url: string) => {
        const maxRetries = 3;
        let delay = 500; // Start with a 1/2 second delay

        const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

        for (let attempt = 1; attempt <= maxRetries; attempt++) {
            try {
                const response = await bundeeRestApi.systemSendMessage(tripId, message);
                if (!response?.data?.accountSid) {
                    throw new Error('Error sending the message');
                } else {
                    console.log('Success sending the message on the trip chat');
                    return; // Exit the function if the message was sent successfully
                }
            } catch (error) {
                console.log(`Attempt ${attempt} failed. ${attempt < maxRetries ? `Retrying in ${delay}ms...` : ''}`);
                if (attempt < maxRetries) {
                    await sleep(delay);
                    delay *= 2; // Double the delay for exponential backoff
                } else {
                    const title = "Message Delivery Failed";
                    const body = `The rental has been signed by ${currentContactData?.contact?.first_name || ''} ${currentContactData?.contact?.last_name || ''} ${currentContactData?.contact?.email || ''}, but we weren't able to push this message on the trip chat for trip ID ${tripId}. Url ${url}. Error: ${error instanceof Error ? error.message : error}`;
                    await sendNotificationToSupport(title, body);
                    console.log('Failed to send the message after max retries');
                }
            }
        }
    };

    const saveSignature = async () => {
        if (sigPad.isEmpty()) {
            // Signature is blank
            showToast('Signature is blank, please sign again', 'error');
            return;
        }
        const signatureImage = sigPad.toDataURL('image/png'); // Convert signature to PNG image

        // send the signature to the backend & with the driver's data
        await sendRentalAgreement(signatureImage);
    };

    const dataURLtoBlob = (dataurl: any) => {
        try {
            var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], { type: mime });
        } catch (error) {
            throw new Error('error to convert the signature to blob');
        }
    };

    const createRandomToken = (length = 24) => {
        // Define the characters that can be used in the token
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let token = '';
        for (let i = 0; i < length; i++) {
            // Generate a random index to pick characters
            const randomIndex = Math.floor(Math.random() * characters.length);
            token += characters.charAt(randomIndex);
        }
        return token;
    };

    const sendRentalAgreement = async (image: any) => {
        try {
            setLoading(true);

            const data = {
                agreement_number: agreementNumber,
                vehicle_make_model: vehicleAndProviderInformation.vehicleMakeModel,
                vehicle_vin: vehicleAndProviderInformation.vehicleVin,
                vehicle_owner: 'Gregg Hansen',
                fuel_out: vehicleAndProviderInformation.fuelOut,
                mileage_out: vehicleAndProviderInformation.mileageOut,
                fuel_in: vehicleAndProviderInformation.fuelIn,
                mileage_in: vehicleAndProviderInformation.mileageIn,
                rental_delivery_time: rentalLogistics.retalDeliveryTime,
                vehicle_return_time: rentalLogistics.vehicleReturnTime,
                vehicle_delivery_location: rentalLogistics.vehicleDeliveryLocation,
                vehicle_return_location: rentalLogistics.vehicleReturnLocation,
                driver_license_number: rentersData.driverLicenseNumber,
                driver_license_state: rentersData.driverLicenseState,
                driver_license_expiration: rentersData.driverLicenseExpiration,
                driver_license_birth_date: rentersData.driverLicenseBirthDate,
                full_name: rentersData.fullName,
                current_address: rentersData.currentAddress,
                email: rentersData.email,
                phone: rentersData.phone,
            };

            const imageBlob = dataURLtoBlob(image);
            if (!imageBlob) throw new Error('error to convert the signature to blob');

            const response = await fluxRestApi.createPdfFromDriversDataAndSignature(imageBlob, data);

            if (!response?.data) throw new Error('error to send the rental agreement');

            // Create a Blob from the PDF Stream
            const file = new Blob([response.data], { type: 'application/pdf' });

            // Create a URL for the Blob
            const fileURL = URL.createObjectURL(file);

            // save the URL to download the pdf
            setUrlPDF(fileURL);

            // create a radom token for the name of the file
            const tokenName = createRandomToken(24);

            // save the file
            const filePdf = new File([response.data], `${tokenName}.pdf`, { type: 'application/pdf' });
            setSavedFile(filePdf);


            // // Create a temporary link element
            // const link = document.createElement('a');
            // link.href = fileURL;
            // link.setAttribute('download', 'rental-agreement.pdf'); // Set the download name

            // // Append the link to the document and trigger the download
            // document.body.appendChild(link);
            // link.click();

            // // Clean up: remove the link and revoke the object URL
            // document.body.removeChild(link);
            // URL.revokeObjectURL(fileURL);

            setLoading(false);
        }
        catch (error) {
            console.error('error to send the rental agreement', error);
            showToast('Oops! Something went wrong.', 'error');
            setLoading(false);
        }
    };

    const savePDF = async () => {
        try {
            if (!savedFile) throw new Error('error to save the pdf');
            if (!currentContactData?.contact?.id) throw new Error('error to save the pdf');

            const data = {
                hubspot_id: currentContactData.contact.id,
                trip_id: agreementNumber,
            }
            const response = await fluxRestApi.savePDFOnTheServer(savedFile, data);
            if (!response?.data?.agreement_pdf) throw new Error('error to save the pdf');

            const url = response.data.agreement_pdf
            await sendMessageAsRobot(agreementNumber, `The Driver has signed the rental agreement. Url: ${url}`, url);
            await setRentalAgreedBundee(agreementNumber, true);
        } catch (erorr) {
            console.error('error to save the pdf', erorr);
            throw new Error('error to save the pdf');
        }

    }

    const handleConfirm = async () => {
        try {
            setLoading(true);
            await savePDF();
            setAgreementAccepted(true);
            onClose();
            setLoading(false);

        } catch (error) {
            console.error('error to confirm the rental agreement', error);
            showToast('Oops! Something went wrong.', 'error');
            setLoading(false);
        }
    }

    // Function to scroll to the bottom of the rental agreement
    const scrollToBottom = () => {
        if (agreementContentRef.current) {
            agreementContentRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    };


    return (
        <div style={modalStyles.overlay}>
            <div style={modalStyles.container}>
                <div style={modalStyles.containerCloseButton}>
                    {!urlPDF && (<>
                        <div style={modalStyles.buttonContent}>
                            <button onClick={scrollToBottom}>
                                Go to the End
                            </button>
                        </div>
                    </>)}
                    <button style={modalStyles.closeButton} onClick={onClose}>
                        X
                    </button>
                </div>
                <div style={modalStyles.scrollableContent}>
                    <RentalAgreement urlPDF={urlPDF} rentalAgreementData={rentalAgreementData} isMobile={isMobile} />
                    {!urlPDF && (<>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                            <div style={{ border: '1px solid black', margin: '10px', width: isMobile ? '200px' : '300px', height: isMobile ? '100px' : '200px' }}>
                                <SignatureCanvas
                                    penColor='black'
                                    canvasProps={{ width: isMobile ? '200px' : '300px', height: isMobile ? '100px' : '200px' }}
                                    ref={(ref) => { sigPad = ref }}
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                            <div ref={agreementContentRef} style={{ ...authStyles.commonStyles.buttonContent, width: isMobile ? '100%' : '30%' }}>
                                <button className="buttonBlack" onClick={() => sigPad.clear()}>
                                    Clear Signature
                                </button>
                                <button onClick={saveSignature} className="buttonBlack">
                                    Confirm Signature
                                </button>
                            </div>
                        </div>
                    </>)}
                    {
                        urlPDF && (
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <div style={{ ...authStyles.commonStyles.buttonContent, width: isMobile ? '100%' : '30%' }}>
                                    <button onClick={handleConfirm} className="buttonBlack">
                                        Confirm
                                    </button>
                                </div>
                            </div>
                        )
                    }
                </div>

            </div>
        </div>
    );
};

interface CarDetailsProps {
    details: any;
    formValues: any;
    handleStartTrip: () => void;
    handleShowRentAgreement: () => void;
    agreementAccepted: boolean;
    rentersData: any;
    rentalLogistics: any;
    vehicleAndProviderInformation: any;
    agreementNumber: any;
    addressLoading: boolean;
    stationDetails: any;
    isMobile: boolean;
    splashScreen: any;
    handleTripChat: () => void;
    currentContactData: any;
    setLoadingExtension: (loadingExtension: boolean) => void;
    setTotalAmount: (totalAmount: number) => void;
    setTotalAmountNew: (totalAmountNew: number) => void;
    setModalConfirmExtension: (modalConfirmExtension: boolean) => void;
    confirmExtension: boolean;
    setConfirmExtension: (confirmExtension: boolean) => void;
    setTripObject: (tripObject: any) => void;
    setLoading: (loading: boolean) => void;
    openModalContactData: () => void;
    openModalAgreements: () => void;
    openModalLicenseVerification: () => void;
    selectedCard: string;
}
const CarDetails: React.FC<CarDetailsProps> = ({ selectedCard, splashScreen, details, formValues, handleStartTrip, handleShowRentAgreement, agreementAccepted, rentersData, rentalLogistics, vehicleAndProviderInformation, agreementNumber, addressLoading, stationDetails, isMobile, handleTripChat, currentContactData, setLoadingExtension, setTotalAmountNew, setTotalAmount, setModalConfirmExtension, confirmExtension, setConfirmExtension, setTripObject, setLoading, openModalContactData, openModalAgreements, openModalLicenseVerification }) => {
    const navigate = useNavigate();
    // States Change trip
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const [isChangingTrip, setIsChangingTrip] = useState<boolean>(false);

    // State to manage the visibility of the End Trip div
    const [showEndTripDiv, setShowEndTripDiv] = useState<boolean>(false);

    // State for the checklist
    const [checklist, setChecklist] = useState<Array<{ text: string, checked: boolean }>>([
        { text: 'Park at the local charger', checked: false },
        { text: 'Plug the car in', checked: false },
        { text: 'Start the charge session', checked: false },
        { text: 'Return the key', checked: false },
        { text: 'Lock the lockbox', checked: false },
        { text: 'Exit the car', checked: false },
        { text: 'Close the doors', checked: false },
        { text: 'Lock the car', checked: false },
        //... add more items
    ]);

    // end trip funtions
    const allChecked = () => checklist.every(item => item.checked);

    const endTripApiCall = async () => {
        if (!allChecked()) {
            return showToast('Please check all the items in the checklist', 'error');
        }
        try {
            setLoading(true);
            const capture = await captureAmount();
            if (capture) {
                const trip = {
                    "tripid": details.tripObject.tripid,
                    "paymentCategoryId": "2",
                    "captureAmount": capture,
                    "comments": "Trip as been ended by Driver",
                    "changedBy": "USER"
                }
                const response = await bundeeRestApi.completeTrip(trip);
                if (response?.data?.errorCode === '0') {
                    showToast('Trip ended successfully', 'success');
                    navigate('/trips');
                } else if (response?.data?.errorCode === '1') {
                    showToast(String(response?.data?.errorMessage) || 'Failed to end trip', 'error');
                } else {
                    console.log('response', response);
                    showToast('Failed to end trip', 'error');
                }
                setLoading(false);
            } else {
                showToast('Failed to end trip', 'error');
                setLoading(false);
            }
        } catch (error) {
            console.log('error', error);
            showToast('Failed to end trip', 'error');
            setLoading(false);
        }
    };

    const captureAmount = async () => {
        try {
            if (!details?.tripObject?.tripid) return null;
            const response = await bundeeRestApi.getAllTripDetailsFromValue('tripId', details?.tripObject?.tripid, '', '');
            if (response?.data?.errorCode === '0' && response?.data?.activetripresponse?.length > 0) {
                // console.log('capture price', response?.data?.activetripresponse[0]?.tripPaymentTokens[0]?.tripTaxAmount);
                return response?.data?.activetripresponse[0]?.tripPaymentTokens[0]?.tripTaxAmount;
            }
        } catch (error) {
            console.log('error', error);
            return null;
        }
    };

    return (
        <>
            <Loader loading={addressLoading} />
            <div style={{ paddingTop: '20px', paddingBottom: isMobile ? '130px' : '20px', width: '100%' }}>
                <div className={stylesCarDetails.card}>
                    {!isChangingTrip ? (<>
                        <h2 className={stylesCarDetails.center}>Trip Details</h2>
                        <div className={stylesCarDetails.containerImage}>
                            <LazyLoadImage
                                src={splashScreen || NoImage}
                                alt="flux-logo"
                                effect="blur"
                                placeholderSrc={placeHolder}
                                className={stylesCarDetails.carImage}
                                onError={(e) => {
                                    e.currentTarget.src = NoImage
                                }}
                            />
                        </div>
                        <div className={stylesCarDetails.carInfoWrapper}>
                            <img src={require('../../images/icons/car-icon.png')} alt="Car Icon" />
                            <span className={stylesCarDetails.CarStationTitle}>{details.carObject.Station || 'Pick up Station'}</span>
                        </div>
                        <div
                            className={stylesCarDetails.carInfoWrapper}
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                if (!stationDetails) return showToast('No address found', 'error');
                                const lat = stationDetails?.geometry?.coordinates[1];
                                const lng = stationDetails?.geometry?.coordinates[0];
                                window.open(`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`, '_blank');
                            }}
                        >
                            <img src={require('../../images/fa-solid_directions.png')} className={stylesCarDetails.addressIcon} alt="Address Icon" />
                            <span className={stylesCarDetails.CarStationTitleAndSmallFont}>{stationDetails?.properties?.address || 'No Address'}</span>
                        </div>
                        <div className={stylesCarDetails.carInfoWrapper}>
                            <div className={stylesCarDetails.iconWrapper}>
                                <img src={require('../../images/icons/calender.png')} alt="Calendar Icon" className={stylesCarDetails.calendarImage} />
                            </div>
                            <div className={stylesCarDetails.dateInfoWrapper}>
                                <span className={stylesCarDetails.dateText}>{formValues.dateFrom}</span>
                                <span className={stylesCarDetails.timeText}>{formValues.timeFrom}</span>
                            </div>
                            <div className={stylesCarDetails.dateRangeWrapper}>
                                <span className={stylesCarDetails.largeFont}>-</span>
                            </div>
                            <div className={stylesCarDetails.dateInfoWrapper}>
                                <span className={stylesCarDetails.dateText}>{formValues.dateTo}</span>
                                <span className={stylesCarDetails.timeText}>{formValues.timeTo}</span>
                            </div>
                        </div>
                        <div className={stylesCarDetails.carInfoWrapper} style={{ cursor: 'pointer' }} onClick={handleTripChat}>
                            <img src={require('../../images/chat_icon.png')} className={stylesCarDetails.addressIcon} alt="Chat Icon" />
                            <span className={stylesCarDetails.CarStationTitleAndSmallFont}>Trip Chat - Chat with us for trip questions</span>
                        </div>
                        <div className={stylesCarDetails.carInfoWrapper}>
                            <img src={require('../../images/fees.png')} className={stylesCarDetails.addressIcon} alt="Chat Icon" />
                            <span className={stylesCarDetails.CarStationTitleAndSmallFont}>Total Rental Charge: {details?.tripObject?.tripPaymentTokens[0]?.tripTaxAmount.toFixed(2) || ''}</span>
                        </div>
                        <div className={stylesCarDetails.carInfoWrapper}>
                            <span className={stylesCarDetails.CarStationTitle}>{details.tripObject.status || 'status'}</span>
                        </div>
                        {showEndTripDiv && (
                            <>
                                <div className={stylesCarDetails.carInfoWrapper}>
                                    <Checklist checklist={checklist} setChecklist={setChecklist} />
                                </div>
                                <div className={stylesCarDetails.buttonContent}>
                                    <button onClick={endTripApiCall} className={stylesCarDetails.buttonBlack}>
                                        End Trip
                                    </button>
                                </div>
                            </>
                        )}
                        {!showEndTripDiv && (
                            <Buttons
                                openModalContactData={openModalContactData}
                                openModalAgreements={openModalAgreements}
                                openModalLicenseVerification={openModalLicenseVerification}
                                currentContactData={currentContactData}
                                details={details}
                                handleShowRentAgreement={handleShowRentAgreement}
                                handleStartTrip={handleStartTrip}
                                isChangingTrip={isChangingTrip}
                                setIsChangingTrip={setIsChangingTrip}
                                setShowEndTripDiv={setShowEndTripDiv}
                                agreementAccepted={agreementAccepted}
                                rentersData={rentersData}
                                rentalLogistics={rentalLogistics}
                                vehicleAndProviderInformation={vehicleAndProviderInformation}
                                agreementNumber={agreementNumber}
                            />
                        )}
                    </>) : (
                        <DatePickerExtension
                            key={details?.tripObject?.starttime}
                            isChangingTrip={isChangingTrip}
                            setIsChangingTrip={setIsChangingTrip}
                            details={details}
                            userTimezone={userTimezone}
                            currentContactData={currentContactData}
                            setLoadingExtension={setLoadingExtension}
                            setTotalAmount={setTotalAmount}
                            setTotalAmountNew={setTotalAmountNew}
                            setModalConfirmExtension={setModalConfirmExtension}
                            confirmExtension={confirmExtension}
                            setConfirmExtension={setConfirmExtension}
                            setTripObject={setTripObject}
                            selectedCard={selectedCard}
                        />
                    )}
                </div>
            </div>
        </>
    );
}

interface ButtonsProps {
    details: any;
    handleStartTrip: () => void;
    isChangingTrip: boolean;
    setIsChangingTrip: (isChangingTrip: boolean) => void;
    setShowEndTripDiv: (showEndTripDiv: boolean) => void;
    handleShowRentAgreement: () => void;
    agreementAccepted: boolean;
    rentersData: any;
    rentalLogistics: any;
    vehicleAndProviderInformation: any;
    agreementNumber: any;
    currentContactData: any;
    openModalContactData: () => void;
    openModalAgreements: () => void;
    openModalLicenseVerification: () => void;
}
const Buttons: React.FC<ButtonsProps> = ({ details, handleStartTrip, isChangingTrip, setIsChangingTrip, setShowEndTripDiv, handleShowRentAgreement, agreementAccepted, rentersData, rentalLogistics, vehicleAndProviderInformation, agreementNumber, currentContactData, openModalContactData, openModalAgreements, openModalLicenseVerification }) => {

    if (!currentContactData?.contact?.city) {
        return (
            <>
                <button
                    onClick={openModalContactData}
                    className="buttonBlack"
                    style={{ marginTop: 10, padding: '10px 20px' }}
                >
                    Update my profile
                </button>
            </>
        )
    };

    if (currentContactData?.contact?.city && (!currentContactData?.contact?.contact_privacyPolicy || !currentContactData?.contact?.contact_agreeTerms)) {
        return (
            <>
                <button
                    onClick={openModalAgreements}
                    className="buttonBlack"
                    style={{ marginTop: 10, padding: '10px 20px' }}
                >
                    Accept the agreements
                </button>
            </>
        )
    };

    if (!currentContactData?.contact?.idscan_request_id) {
        return (
            <>
                <button
                    onClick={openModalLicenseVerification}
                    className="buttonBlack"
                    style={{ marginTop: 10, padding: '10px 20px' }}
                >
                    Verify my license
                </button>
            </>
        )
    };

    return (
        <>
            {details?.tripObject?.statusCode === 'REAPP' && (
                <>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 30 }}>
                        {
                            (!agreementAccepted && rentersData && rentalLogistics && vehicleAndProviderInformation && agreementNumber) ? (
                                <button
                                    onClick={handleShowRentAgreement}
                                    className="buttonBlack"
                                    style={{ marginTop: 10, padding: '10px 20px' }}
                                >
                                    Sign Rental Agreement
                                </button>) :
                                agreementAccepted && rentersData && rentalLogistics && vehicleAndProviderInformation && agreementNumber ? (
                                    <button
                                        onClick={() => handleStartTrip()}
                                        className="buttonBlack"
                                        style={{ marginTop: 10, paddingLeft: 15, paddingRight: 15 }}
                                    >
                                        Start Trip
                                    </button>
                                ) :
                                    <button className="buttonBlack" disabled style={{ marginTop: 10, paddingLeft: 15, paddingRight: 15, cursor: 'not-allowed' }}>
                                        Loading...
                                    </button>
                        }
                    </div>
                </>
            )}

            {details?.tripObject?.statusCode === 'TRSTR' && (
                <div style={{ ...authStyles.commonStyles.buttonContent, marginTop: 30, width: '100%' }}>
                    {(!agreementAccepted && rentersData && rentalLogistics && vehicleAndProviderInformation && agreementNumber) ? (
                        <button
                            onClick={handleShowRentAgreement}
                            className="buttonBlack"
                            style={{ marginTop: 10, padding: '10px 20px' }}
                        >
                            Sign Rental Agreement
                        </button>
                    ) : agreementAccepted ? !isChangingTrip ? (
                        <>
                            <button onClick={() => setShowEndTripDiv(true)} className="buttonBlack">
                                End Trip
                            </button>
                            <button onClick={() => setIsChangingTrip(true)} className="buttonBlack">
                                Extend Trip
                            </button>
                        </>
                    ) : (
                        null
                    ) : (
                        <button className="buttonBlack" disabled style={{ marginTop: 10, paddingLeft: 15, paddingRight: 15, cursor: 'not-allowed' }}>
                            Loading...
                        </button>
                    )}
                </div>
            )}
        </>
    );
};

interface ChecklistProps {
    checklist: Array<{ text: string, checked: boolean }>;
    setChecklist: React.Dispatch<React.SetStateAction<Array<{ text: string, checked: boolean }>>>;
}

const Checklist: React.FC<ChecklistProps> = ({ checklist, setChecklist }) => {

    const handleCheckboxChange = (index: number) => {
        const newChecklist = [...checklist];
        newChecklist[index].checked = !newChecklist[index].checked;
        setChecklist(newChecklist);
    }

    return (
        <div style={{ marginBottom: '15px' }}>
            <h3 style={{ textAlign: 'center' }}>End Trip Check list</h3>
            {checklist.map((item, index) => (
                <label key={index} style={{ display: 'block' }}>
                    <input
                        type="checkbox"
                        checked={item.checked}
                        onChange={() => handleCheckboxChange(index)}
                    />
                    <span style={{ marginLeft: 10 }}>{item.text}</span>
                </label>
            ))}
        </div>
    );
}

interface ExtendConfirmationModalProps {
    totalPrice: any;
    totalNewPrice: any;
    isOpen: boolean;
    onClose: () => void;
    setConfirmExtension: (confirmExtension: boolean) => void;
    stripeId: string;
    selectedCard: string;
    setSelectedCard: (selectedCard: string) => void;
}

const ExtendConfirmationModal: React.FC<ExtendConfirmationModalProps> = ({ setSelectedCard, selectedCard, stripeId, totalPrice, totalNewPrice, isOpen, onClose, setConfirmExtension }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [cards, setCards] = useState<any[]>([]);
    const [loadingCards, setLoadingCards] = useState<boolean>(true);
    const [showAddCardModal, setShowAddCardModal] = useState<boolean>(false);

    const fetchCards = useCallback(async () => {
        try {
            setLoadingCards(true);
            const response = await fluxRestApi.getPaymentMethods(stripeId);
            if (response?.data?.payment_methods?.length > 0) {
                const transformedCards = response?.data?.payment_methods.map((card: any) => {
                    return {
                        id: card.id,
                        brand: card.card.brand,
                        fingerprint: card.card.fingerprint,
                        last4: card.card.last4,
                        exp_month: card.card.exp_month,
                        exp_year: card.card.exp_year
                    };
                });
                setCards(transformedCards);
                setLoadingCards(false);
                console.log('getPaymentMethods');
            } else {
                showToast('No payment methods found', 'info');
                setCards([]);
                setLoadingCards(false);
            }
        } catch (error) {
            setLoadingCards(false);
            console.error(error);
        }
    }, [stripeId]);

    useEffect(() => {
        if (isOpen && stripeId) {
            fetchCards();
        }
    }, [isOpen, fetchCards, stripeId]);

    if (!isOpen) return null;

    const stylesCards: { [key: string]: CSSProperties } = {
        cardContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom: '1rem',
            padding: '1rem',
            border: '1px solid #ccc',
            borderRadius: '8px',
            cursor: 'pointer',
            transition: 'background-color 0.3s ease',
        },
        cardContainerSelected: {
            backgroundColor: '#f0f0f0',
        },
        cardContainerHover: {
            backgroundColor: '#e0e0e0',
        },
        cardDetails: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        cardBrand: {
            fontSize: '1.2rem',
            fontWeight: 'bold',
        },
        cardLast4: {
            fontSize: '1rem',
            color: '#555',
        },
        cardExpiry: {
            fontSize: '0.9rem',
            color: '#888',
        },
        cardButtonContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
        },
        addCardButton: {
            backgroundColor: '#007bff',
            color: '#fff',
            border: 'none',
            borderRadius: '4px',
            padding: '0.5rem 1rem',
            cursor: 'pointer',
            marginTop: '1rem',
        },
        cardListContainer: {
            maxHeight: '250px',
            overflowY: 'auto',
            width: '100%',
            marginBottom: '1rem',
        },
    };

    const handleConfirmExtension = async () => {
        if (!selectedCard) {
            return showToast('Please select a card', 'error');
        }
        try{
            setConfirmExtension(true)
        } catch (error) {
            console.error('error', error);
            showToast('Failed to confirm the extension', 'error');
        }
    };

    return (
        <div className={stylesModal.modalOverlay}>
            <div className={stylesModal.modalContent}>
            {!showAddCardModal ? (<>
                <button className={stylesModal.closeButton} onClick={onClose}>
                    &times;
                </button>
                <h2 className={stylesModal.modalHeadingExtension}>Extension Price</h2>
                {loadingCards ? (
                    <p>Loading cards...</p>
                ) : (
                    <>
                        {cards.length > 0 ? (
                            <div style={stylesCards.cardListContainer}>
                                {cards.map((card) => (
                                    <div
                                        key={card.id}
                                        style={{
                                            ...stylesCards.cardContainer,
                                            ...(selectedCard === card.id ? stylesCards.cardContainerSelected : {}),
                                        }}
                                        onClick={() => {
                                            setSelectedCard(card.id);
                                        }}
                                        onMouseEnter={(e) => {
                                            (e.currentTarget as HTMLElement).style.backgroundColor = stylesCards.cardContainerHover.backgroundColor || '';
                                        }}
                                        onMouseLeave={(e) => {
                                            (e.currentTarget as HTMLElement).style.backgroundColor = selectedCard === card.id ? stylesCards.cardContainerSelected.backgroundColor || '' : '';
                                        }}
                                    >
                                        <div style={stylesCards.cardDetails}>
                                            <span style={stylesCards.cardBrand}>{card.brand}</span>
                                            <span style={stylesCards.cardLast4}>**** **** **** {card.last4}</span>
                                            <span style={stylesCards.cardExpiry}>
                                                {card.exp_month}/{card.exp_year}
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p>No cards available.</p>
                        )}
                    </>
                )}
                <div style={stylesCards.cardButtonContainer}>
                    <button style={stylesCards.addCardButton} onClick={() => setShowAddCardModal(true)}>Add New Card</button>
                </div>
                <div className={stylesModal.modalBody}>
                    <p>Total Price: {totalPrice}</p>
                    <p>New Total Price: {totalNewPrice}</p>
                </div>
                <div className={stylesModal.modalContainerButtons}>
                    <button className={stylesModal.modalButtonCancel} onClick={onClose}>
                        Cancel
                    </button>
                    <button className={stylesModal.modalButtonConfirm} onClick={handleConfirmExtension}>
                        Confirm
                    </button>
                </div>
                </>) : (
                    <CardForm
                        stripeId={stripeId}
                        stripe={stripe}
                        elements={elements}
                        setShowCardForm={setShowAddCardModal}
                        loading={loadingCards}
                        setLoading={setLoadingCards}
                        isMobile={true}
                        animate={true}
                        fetchCards={fetchCards}
                        cards={cards}
                    />
                )}
            </div>
        </div>
    );
};

export default TripDetails;
