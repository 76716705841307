import axios, { AxiosInstance } from 'axios';
// Sentry for error tracking
import * as Sentry from "@sentry/react";

const baseURL = process.env.REACT_APP_BUNDEE_API_URL;
const baseURL2 = process.env.REACT_APP_BUNDEE_API_URL2;
const baseURL3 = process.env.REACT_APP_BUNDEE_API_URL3;
const baseURL4 = process.env.REACT_APP_BUNDEE_API_URL4;
const baseURL5 = process.env.REACT_APP_BUNDEE_API_URL5;
const baseURL6 = process.env.REACT_APP_BUNDEE_API_URL6;

const hostId = process.env.REACT_APP_BUNDEE_HOST_ID;
const bundeeAuthToken = process.env.REACT_APP_BUNDEE_AUTH_TOKEN;
const password = process.env.REACT_APP_BUNDEE_PASSWORD;

// Create Axios instances for each baseURL with appropriate headers
const api1: AxiosInstance = axios.create({
    baseURL: baseURL,
    timeout: 1200000,
    headers: {
        'bundee_auth_token': bundeeAuthToken || '',
        'Content-Type': 'application/json',
    }
});

const api2: AxiosInstance = axios.create({
    baseURL: baseURL2,
    timeout: 1200000,
    headers: {
        'bundee_auth_token': bundeeAuthToken || '',
        'Content-Type': 'application/json',
    }
});

const api3: AxiosInstance = axios.create({
    baseURL: baseURL3,
    timeout: 1200000,
    headers: {
        'bundee_auth_token': bundeeAuthToken || '',
        'Content-Type': 'application/json',
    }
});

const api4: AxiosInstance = axios.create({
    baseURL: baseURL4,
    timeout: 1200000,
    headers: {
        'bundee_auth_token': bundeeAuthToken || '',
        'Content-Type': 'application/json',
    }
});

const api5: AxiosInstance = axios.create({
    baseURL: baseURL5,
    timeout: 1200000,
    headers: {
        'bundee_auth_token': bundeeAuthToken || '',
        'Content-Type': 'application/json',
    }
});

const api6: AxiosInstance = axios.create({
    baseURL: baseURL6,
    timeout: 1200000,
    headers: {
        'bundee_auth_token': bundeeAuthToken || '',
        'Content-Type': 'application/json',
    }
});

// Optionally, handle common error responses globally
const handleError = (methodName: string, error: any) => {
    console.error(methodName, error);
    return null;
};

class BundeeRestApi {
    // 1. Get Availability Dates by Vehicle ID
    getAvailabilityDatesByVehicleId = async (vehicleId: string): Promise<any> => {
        try {
            const response = await api1.post("availability/getAvailabilityDatesByVehicleId", {
                vehicleid: vehicleId
            });
            return response.status === 200 ? response : null;
        } catch (e: any) {
            return handleError('getAvailabilityDatesByVehicleId', e);
        }
    };

    // 2. Create Reservation
    createReservation = async (reservation: any): Promise<any> => {
        try {
            const response = await api2.post("booking/createReservation", reservation);
            return response.status === 200 ? response : null;
        } catch (e: any) {
            Sentry.captureException(e);
            return handleError('createReservation', e);
        }
    };

    // 3. Approve Reservation
    approveReservation = async (tripid: string): Promise<any> => {
        try {
            const payload = {
                tripid: tripid,
                comments: "your trip is Approved"
            };
            const response = await api2.post("booking/updateReservationApproval", payload);
            return response.status === 200 ? response : null;
        } catch (e: any) {
            return handleError('approveReservation', e);
        }
    };

    // 4. Get All Trip Details from Value
    getAllTripDetailsFromValue = async (fromValue: any, id: any, startTime: string, endTime: string): Promise<any> => {
        try {
            const payload: any = {
                fromValue: fromValue,
                id: id,
            };
            if (startTime) payload.startTime = startTime;
            if (endTime) payload.endTime = endTime;

            const response = await api2.post("booking/getActiveTripById", payload);
            return response.status === 200 ? response : null;
        } catch (e: any) {
            return handleError('getAllTripDetailsFromValue', e);
        }
    };

    // 5. Start Trip
    startTrip = async (tripid: string): Promise<any> => {
        try {
            const payload = {
                "tripid": tripid,
                "changedBy": "USER",
                "comments": "Trip started from driver"
            };
            const response = await api2.post("booking/updateReservationStart", payload);
            return response.status === 200 ? response : null;
        } catch (e: any) {
            return handleError('startTrip', e);
        }
    };

    // 6. Create Extension
    createExtension = async (extension: any): Promise<any> => {
        try {
            const response = await api6.post("booking/createTripModificationExtension", extension);
            return response.status === 200 ? response : null;
        } catch (e: any) {
            return handleError('createExtension', e);
        }
    };

    // 7. Approve Extension
    approveExtension = async (extension: any): Promise<any> => {
        try {
            const payload = {
                tripId: extension.tripId,
                startTime: extension.startTime,
                endTime: extension.endTime,
                comments: "your Extend is Approved"
            };
            const response = await api2.post("booking/TripModificationExtensionApproval", payload);
            return response.status === 200 ? response : null;
        } catch (e: any) {
            return handleError('approveExtension', e);
        }
    };

    // 8. Cancel Reservation
    cancelReservation = async (tripid: string): Promise<any> => {
        try {
            const payload = { tripid };
            const response = await api2.post("booking/updateReservationRejected", payload);
            return response.status === 200 ? response : null;
        } catch (e: any) {
            return handleError('cancelReservation', e);
        }
    };

    // 9. Complete Trip
    completeTrip = async (trip: any): Promise<any> => {
        try {
            const response = await api2.post("booking/updateReservationCompleted", trip);
            return response.status === 200 ? response : null;
        } catch (e: any) {
            return handleError('completeTrip', e);
        }
    };

    // 10. Create Bundee User
    createBundeeUser = async (userData: any): Promise<any> => {
        try {
            const response = await api3.post("user/createUser", userData);
            return response.status === 200 ? response : null;
        } catch (e: any) {
            return handleError('createBundeeUser', e);
        }
    };

    // 11. Get User by Email
    getUserByEmail = async (email: string): Promise<any> => {
        try {
            const payload = {
                channelName: "Flux",
                email: email
            };
            const response = await api3.post("user/getUserByEmail", payload);
            return response.status === 200 ? response : null;
        } catch (e: any) {
            return handleError('getUserByEmail', e);
        }
    };

    // 12. Get Vehicle Data
    getVehicleData = async (vehicleid: string): Promise<any> => {
        try {
            const payload = { vehicleid };
            const response = await api1.post("availability/getVehiclesnFeaturesById", payload);
            return response.status === 200 ? response : null;
        } catch (e: any) {
            return handleError('getVehicleData', e);
        }
    };

    // 13. Calculate Prices
    calculatePrices = async (data: any): Promise<any> => {
        try {
            const payload: any = {
                vehicleid: data.vehicleid,
                startTime: data.startTime,
                endTime: data.endTime,
                airportDelivery: false,
                customDelivery: false,
                hostid: hostId
            };
            if (data.tripId) payload.tripid = data.tripId;

            const response = await api4.post("vehicle/calculatePrice", payload);
            return response.status === 200 ? response : null;
        } catch (e: any) {
            return handleError('calculatePrices', e);
        }
    };

    // 14. Chat Service
    chatService = async (tripId: string): Promise<any> => {
        try {
            const payload = {
                tripId: tripId,
                password: password
            };
            const response = await api5.post('getAllChatAssets', payload);
            return response.status === 200 ? response : null;
        } catch (e: any) {
            return handleError('chatService', e);
        }
    };

    // 15. Search Vehicles by Dates
    searchVehiclesByDates = async (data: any): Promise<any> => {
        try {
            const response = await api1.post("availability/getByZipCode", data);
            return response.status === 200 ? response : null;
        } catch (e: any) {
            return handleError('searchVehiclesByDates', e);
        }
    };

    // 16. System Send Message
    systemSendMessage = async (tripId: string, message: string): Promise<any> => {
        try {
            const payload = {
                tripId: tripId,
                message: message,
                password: password
            };
            const response = await api5.post('systemSendMessage', payload);
            return response.status === 200 ? response : null;
        } catch (e: any) {
            return handleError('systemSendMessage', e);
        }
    };

    // 17. Notification Bundee
    notificationBundee = async (body: any): Promise<any> => {
        try {
            const response = await api2.post("booking/pushNotification", body);
            return response.status === 200 ? response : null;
        } catch (e: any) {
            return handleError('notificationBundee', e);
        }
    };

    // 18. Verify License Status Bundee
    verifyLicenseStatusBundee = async (data: any): Promise<any> => {
        try {
            const response = await api3.post("user/createDriverProfile", data);
            return response.status === 200 ? response : null;
        } catch (e: any) {
            return handleError('verifyLicenseStatusBundee', e);
        }
    };

    // 19. Check if Phone is Verified
    isPhoneVerified = async (data: any): Promise<any> => {
        try {
            const payload = {
                mobilePhone: data.mobilePhone,
                isPhoneVarified: data.isPhoneVarified,
                iduser: data.iduser
            };
            const response = await api3.post("user/updateUserPhoneNumber", payload);
            return response.status === 200 ? response : null;
        } catch (e: any) {
            return handleError('isPhoneVerified', e);
        }
    };

    // 20. Check if Rental is Agreed
    isRentalAgreed = async (data: any): Promise<any> => {
        try {
            const payload = {
                isRentalAgreed: data.isRentalAgreed,
                tripId: data.tripId
            };
            const response = await api2.post("booking/updateRentalAgreement", payload);
            return response.status === 200 ? response : null;
        } catch (e: any) {
            return handleError('isRentalAgreed', e);
        }
    };
}

const bundeeRestApi = new BundeeRestApi();

export default bundeeRestApi;
