import React, { useEffect, useRef, useState, useMemo } from 'react';
// assets
import point_green_url from '../../images/icons/car_station.png';
// hooks
// components
import { Wrapper, Status } from '@googlemaps/react-wrapper';

const renderLoading = (status: Status): React.ReactElement => {
    switch (status) {
        case Status.LOADING:
            return <h1>Loading...</h1>;
        case Status.FAILURE:
            return <h1>Error loading maps</h1>;
        default:
            return <></>;
    }
};

interface Feature {
    type: string;
    properties: {
        name: string;
        address: string;
        link: string;
        routeName: string;
        icon?: string; // Optional icon property
        isMain?: boolean; // To distinguish main from step markers
    };
    geometry: {
        type: string;
        coordinates: [number, number];
    };
}

interface MapProps {
    initialLatitude: number;
    initialLongitude: number;
    initialZoom: number;
    width?: string | number;
    height?: string | number;
    features: Feature[];
    onMarkerClick?: (routeName: string) => void;
}

const MapComponent: React.FC<MapProps> = ({
    initialLatitude,
    initialLongitude,
    initialZoom,
    width,
    height,
    features,
    onMarkerClick,
}) => {
    return (
        <>
            <Wrapper apiKey={process.env.REACT_APP_GOOGLE_API_KEY || ''} render={renderLoading}>
                <Map
                    initialLatitude={initialLatitude}
                    initialLongitude={initialLongitude}
                    initialZoom={initialZoom}
                    width={width}
                    height={height}
                    features={features}
                    onMarkerClick={onMarkerClick}
                />
            </Wrapper>
        </>
    );
};

const Map: React.FC<MapProps> = ({
    initialLatitude,
    initialLongitude,
    initialZoom,
    width,
    height,
    features,
    onMarkerClick,
}) => {
    // refs
    const ref = useRef<HTMLDivElement>(null);
    const markerRefs = useRef<google.maps.marker.AdvancedMarkerElement[]>([]);
    // states
    const [map, setMap] = useState<google.maps.Map | null>(null);
    const center = useMemo(() => ({ lat: initialLatitude, lng: initialLongitude }), [initialLatitude, initialLongitude]);
    const zoom = initialZoom;

    // Initialize the map
    useEffect(() => {
        if (ref.current && !map) {
            const newMap = new window.google.maps.Map(ref.current, {
                center,
                zoom,
                disableDefaultUI: false,
                zoomControl: true,
                mapTypeControl: false,
                mapId: '6d5ee41397cfdef1',
            });
            setMap(newMap);
        }
    }, [ref, map, center, zoom]);

    // Load markers
    useEffect(() => {
        if (!map || features.length === 0) return;

        // Function to determine which icon to use based on the feature's properties
        const determineIcon = (feature: Feature) => {
            return feature.properties.icon || point_green_url;
        };

        const loadMarkers = async () => {
            // Clean up existing markers
            markerRefs.current.forEach((marker) => {
                if (marker.element && marker.element.parentNode) {
                    marker.element.parentNode.removeChild(marker.element); // Remove from DOM
                }
            });
            markerRefs.current = [];

            const { AdvancedMarkerElement } = (await google.maps.importLibrary(
                'marker'
            )) as google.maps.MarkerLibrary;

            features.forEach((feature) => {
                const icon = determineIcon(feature);
                const Img = document.createElement('img');
                Img.src = icon;
                Img.style.width = '50px';
                Img.style.cursor = 'pointer';

                const marker = new AdvancedMarkerElement({
                    position: { lat: feature.geometry.coordinates[1], lng: feature.geometry.coordinates[0] },
                    map: map,
                    title: feature.properties.name,
                    content: Img,
                });

                // Add click listener to handle marker selection
                marker.addListener('click', () => {
                    if (feature.properties.isMain) { // Only main markers trigger route selection
                        if (feature?.properties?.link && feature?.properties?.link !== '#') {
                            window.open(feature.properties.link, '_blank');
                        }
                        if (onMarkerClick) {
                            onMarkerClick(feature.properties.routeName);
                        }
                    } else {
                        // For step markers, only open link if available
                        if (feature?.properties?.link && feature?.properties?.link !== '#') {
                            window.open(feature.properties.link, '_blank');
                        }
                    }
                });

                markerRefs.current.push(marker);
            });
        };

        loadMarkers();

        // Clean up markers on unmount or when features change
        return () => {
            // Clean up markers
            markerRefs.current.forEach((marker) => {
                if (marker.element && marker.element.parentNode) {
                    marker.element.parentNode.removeChild(marker.element); // Remove from DOM
                }
            });
            markerRefs.current = [];
        };
    }, [map, features, onMarkerClick]);

    return <div ref={ref} style={{ width, height }} />;
};

export default React.memo(MapComponent);