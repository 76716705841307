interface RoundedButtonProps {
    title?: string;
    onPress?: () => void;
    className?: string;
    circle?: boolean;
    icon?: string;
    disabled?: boolean;
}

const RoundedButton = ({ title, onPress, className, circle, icon, disabled} : RoundedButtonProps) => {
    return (
        <button 
            className={`
                flex items-center justify-center 
                text-black rounded-full shadow-strong text-sm 
                transition-all duration-150
                ${className} 
                ${circle ? "p-3" : "p-3 w-full"} 
                ${disabled ? "bg-gray-100 cursor-not-allowed" : "bg-white cursor-pointer active:scale-95 active:shadow-md"}
            `} 
            onClick={onPress}
            disabled={disabled}
        >
            {icon && <img src={icon} alt="icon" className="h-4 mr-3" />}
            <p>{title}</p>
        </button>
    )
}

export default RoundedButton;