// CheckoutForm.tsx
import React, { useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import styled from 'styled-components';
import showToast from '../../utils/toastHelpers';
import fluxRestApi from '../../services/FluxRestApi';

const FLUX_API_URL = process.env.REACT_APP_FLUX_API_URL;
const CheckoutForm: React.FC = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [isPaymentComplete, setIsPaymentComplete] = useState<boolean>(false);

    const sendPushNotification = async () => {
        const data = {
            "title": "Adapter Payment",
            "body": `Payment for adapter has been made`,
            "send_sms": true,
            "send_email": true,
        }
        try {
            const push = await fluxRestApi.sendNotificationToSupportUsers(data.title, data.body, data.send_sms, data.send_email);
            if (push !== null) {
                console.log('Notification sent successfully');
            } else {
                console.log('Something went wrong with the notification');
            }
        } catch (error) {
            console.error('Error sending push notification', error);
        }
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't loaded yet.
            return;
        }

        setIsProcessing(true);
        setErrorMessage(null);

        const result = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Replace with your actual return URL
                return_url: `${FLUX_API_URL}/rental-adapter`
            },
            redirect: 'if_required', // You can choose 'always' or 'if_required'
        });

        if (result.error) {
            // Show error to your customer
            setErrorMessage(result.error.message || 'An unexpected error occurred.');
        } else {
            // Payment succeeded or is processing
            // You might want to handle redirection or success state here
            showToast('Payment succeeded', 'success');
            console.log('Payment succeeded:', result.paymentIntent);
            sendPushNotification();
            setIsPaymentComplete(true);
        }

        setIsProcessing(false);
    };

    if (isPaymentComplete) {
        return <SuccessMessage>Payment complete! 🎉</SuccessMessage>;
    }

    return (
        <Form onSubmit={handleSubmit}>
            <PaymentElement />
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            <SubmitButton type="submit" disabled={!stripe || isProcessing}>
                {isProcessing ? 'Processing...' : 'Submit Payment'}
            </SubmitButton>
        </Form>
    );
};

export default CheckoutForm;

// Styled Components for CheckoutForm
const SuccessMessage = styled.div`
    color: #000000;
    margin-top: 10px;
    font-size: 14px;
    `;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: stretch;
`;

const SubmitButton = styled.button`
    background-color: #C7F100;
    color: #000000;
    padding: 14px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s ease;
    margin-top: 20px;

    &:hover {
        background-color: #b2d700;
    }

    &:disabled {
        background-color: #e3e3e3;
        cursor: not-allowed;
    }

    @media (max-width: 480px) {
        padding: 12px;
        font-size: 14px;
    }
    `;

const ErrorMessage = styled.div`
    color: red;
    margin-top: 10px;
    font-size: 14px;
    `;
