import { useState, useEffect, Dispatch, SetStateAction } from "react";

import { useSwipeable } from "react-swipeable";

import chevronRight from "../../images/icons/chevron-right-flux.svg";

const STATES = {
  locked: "locked",
  unlocking: "unlocking",
  unlocked: "unlocked",
};

interface SwipeableButtonProps {
  state: string;
  setState: Dispatch<SetStateAction<string>>;
  lockedMessage?: string;
  unlockingMessage?: string;
  unlockedMessage?: string;
  rightElement?: React.ReactNode;
}

const SwipeableButton = ({
  state,
  setState,
  lockedMessage,
  unlockedMessage,
  unlockingMessage,
  rightElement,
}: SwipeableButtonProps) => {
  const [delta, setDelta] = useState(0);
  const [deltaPercentage, setDeltaPercentage] = useState(0);
  const [maxSwipeValue, setMaxSwipeValue] = useState(200);
  const [readyToUnlock, setReadyToUnlock] = useState(false);

  const onSwiping = ({ deltaX }: any) => {
    if (deltaX <= 0) {
      setDelta(0);
      return;
    }
    const delta = Math.round(deltaX);
    const slideValue = (delta * 100) / maxSwipeValue;
    if (slideValue >= 100) return;
    setDelta(delta);
    setDeltaPercentage(slideValue);
    if (slideValue > 75 && !readyToUnlock) {
      console.log("ready to unlock");
      setReadyToUnlock(true);
    } else if (slideValue <= 75 && readyToUnlock) {
      setReadyToUnlock(false);
    }
  };

  const onSwiped = () => {
    if (readyToUnlock) {
      setState(STATES.unlocking);
    }
    setDelta(0);
    setDeltaPercentage(0);
    setReadyToUnlock(false);
  };

  const handlers = useSwipeable({
    onSwiped: onSwiped,
    onSwiping: onSwiping,
    delta: 10,
    trackMouse: true,
  });

  useEffect(() => {
    const slider = document.getElementById("slider");
    if (slider) {
      setMaxSwipeValue(slider.offsetWidth - 50);
    }
  }, []);
  return (
    <div className="relative text-xs">
      <div
        className={`flex w-full items-center p-2 text-xs rounded-full top-0 ${
          state === STATES.unlocked
            ? "bg-flux"
            : state === STATES.unlocking
            ? "bg-gray-200 animate-pulse relative"
            : "bg-gray-200 absolute"
        }`}
      >
        <div
          style={{
            transform: `translateX(${delta}px)`,
            transitionProperty: "transform",
            transitionDuration: "100ms",
          }}
          className={
            state === STATES.locked
              ? `h-9 w-9 bg-white rounded-full relative transition-transform`
              : "h-9 w-9"
          }
        >
          {state === STATES.locked && (
            <img src={chevronRight} alt="chevron-right" className="h-10" />
          )}
        </div>
        <div className="flex-1 text-center font-semibold">
          {state === STATES.unlocking
            ? unlockingMessage || "UNLOCKING..."
            : state === STATES.locked
            ? lockedMessage || "SWIPE RIGHT TO UNLOCK"
            : unlockedMessage || "READY"}
        </div>
        {state === STATES.unlocked && rightElement && (
          <div className="absolute right-2 opacity-50">
            {rightElement}
          </div>
        )}
      </div>
      {state === STATES.locked && (
        <div
          id="slider"
          {...handlers}
          className={`flex items-center bg-flux-light p-2 text-xs rounded-full relative`}
          style={{ opacity: 1 - deltaPercentage / 85 }} // 85 is because we want to show the complete transition before the end
        >
          <div
            style={{
              transform: `translateX(${delta}px)`,
              transitionProperty: "transform",
              transitionDuration: "100ms",
            }}
            className={`h-9 w-9 bg-white rounded-full relative transition-transform`}
          >
            <img src={chevronRight} alt="chevron-right" className="h-10" />
          </div>
          <div className="flex-1 text-center font-semibold">
            {lockedMessage || "SWIPE RIGHT TO UNLOCK"}
          </div>
        </div>
      )}
    </div>
  );
};

export default SwipeableButton;
