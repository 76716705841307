import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
// context
import { useAuth } from "../Context/AuthContext";
import { useIsMobile } from '../hooks/useIsMobile';
// components
import Account from "../pages/Account";
import ChargingStations from "../pages/ChargingStations";
import Login from "../pages/Login";
import SignUp from "../pages/SignUp";
import Contact from "../pages/Contact";
import NotFound from "../pages/NotFound";
import Agreements from "../pages/Agreements";
import Payment from "../pages/Payment";
import Trips from "../pages/Trips";
import TripDetails from "../components/trips/TripDetails";
import Booking from "../pages/Booking";
import Vehicles from "../pages/Vehicles";
import Confirm from "../pages/Confirm";
import ReservationSuccess from "../pages/ReservationSuccess";
import Idscan from "../pages/Idscan";
import InsuranceMeasureOne from "../pages/InsuranceMeasureOne";
import VerifyMyPhone from "../pages/VerifyMyPhone";
import License from "../pages/License";
// the mobile flux app pages
import VerifyLicenseMobileFluxApp from "../pages/VerifyLicenseMobileFluxApp";
import VerifyMyInsuranceMobileFluxApp from "../pages/VerifyMyInsuranceMobileFluxApp";
import MobileApp from "../pages/MobileApp";
// landing page for a sharable map that offers a user suggestions on how to get to Houston, Dallas
import MapHowToGetTo from "../pages/MapHowToGetTo";
// landing page to sign up Turo users into the flux app
import SignUpFromTuro from "../pages/SignUpFromTuro";
// landing page for rental adapters
import RentalAdapter from "../pages/adapters/RentalAdapter";
// Mock screen for digital key
import MockDigitalKey from "../pages/mock_screens/MockDigitalKey";


// mobile app redirect handler
const RedirectHandler: React.FC = () => {
    const location = useLocation();

    useEffect(() => {
        if (location.pathname.startsWith('/mobile-app')) {
            const newPath = location.pathname.replace('/mobile-app', 'fluxev://') + location.search;
            window.location.href = newPath;
        }
    }, [location]);

    return null;
};

const AppRoutes: React.FC = () => {
    const { user } = useAuth();
    const isMobile = useIsMobile();

    return (
        <>
            <RedirectHandler />
            <div id="app-routes-component"  style={{ paddingTop: isMobile ? '67px' : '77px' }}>
                <Routes>
                    <Route path="/" element={<ChargingStations />} />
                    <Route path="/mobile-app/*" element={<MobileApp />} />
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="/login" element={<Login />} />
                    {/* Shareable map that offers a user suggestions on how to get to Houston, Dallas */}
                    <Route path="/how-to-get-to" element={<MapHowToGetTo />} />
                    {/* Booking routes */}
                    <Route path="/booking" element={<Booking />} />
                    <Route path="/booking/vehicles" element={<Vehicles />} />
                    <Route path="/booking/confirm" element={<Confirm />} />
                    <Route path="/reservation-success" element={<ReservationSuccess />} />
                    <Route path="/idscan" element={<Idscan />} />
                    <Route path="/verify-license-mobile-flux-app" element={<VerifyLicenseMobileFluxApp />} />
                    <Route path="/verify-insurance-mobile-flux-app" element={<VerifyMyInsuranceMobileFluxApp />} />
                    <Route path="/turo-trip" element={<SignUpFromTuro />} />
                    <Route path="/rental-adapter" element={<RentalAdapter />} />
                    <Route path="/digital-key" element={<MockDigitalKey />} />
                    {
                        user && (
                            <>
                                <Route path="/account" element={<Account />} />
                                <Route path="/contact" element={<Contact />} />
                                <Route path="/agreements" element={<Agreements />} />
                                <Route path="/payment" element={<Payment />} />
                                <Route path="/trips" element={<Trips />} />
                                <Route path="/trips/:tripId" element={<TripDetails />} />
                                <Route path="/insurance" element={<InsuranceMeasureOne />} />
                                <Route path="/verify-phone" element={<VerifyMyPhone />} />
                                <Route path="/license" element={<License />} />
                            </>
                        )
                    }

                    {/* Fallback route */}
                    <Route path="*" element={<NotFound />} />

                </Routes>
            </div>
        </>
    );
}

export default AppRoutes;