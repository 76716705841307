import { useEffect, useState } from "react";

import RoundedButton from "./RoundedButton";
import SwipeableButton from "./SwipeableButton";
import CountdownTimer from "./CountdownTimer";
import CarLocationMap from './CarLocationMap';

import fluxLogo from "../../images/Flux_Header.png";
import arrowLeftUp from "../../images/icons/arrow-left-up.svg";
import arrowRightUp from "../../images/icons/arrow-right-up.svg";
import locked from "../../images/icons/locked.svg";

const CAR_DATA = {
  name: "COMO 2204",
  expires: "Dec 31, 2023 11:59 PM",
  location: {
    lat: 30.2805873,
    lng: -97.7452201,
  },
};

const MockDigitalKey: React.FC = () => {
  const [state, setState] = useState("locked");
  const [showMap, setShowMap] = useState(false);

  const handleTimerComplete = () => {
    setState("locked");
  };

  // mock function to simulate the unlock process
  useEffect(() => {
    if (state === "unlocking") {
      setTimeout(() => {
        setState("unlocked");
      }, 4000);
    }
  }, [state]);

  // Remove the top bar
  useEffect(() => {
    let display = "";
    let topPadding = "";
    const topBar = document.getElementById("TopBar");
    const mainComponent = document.getElementById("app-routes-component");
    if (topBar && mainComponent) {
      display = topBar.style.display;
      topBar.style.display = "none";
      topPadding = mainComponent.style.paddingTop;
      mainComponent.style.paddingTop = "0px";
    }
    // Cleanup event listener on unmount
    return () => {
      if (topBar && mainComponent) {
        topBar.style.display = display;
        mainComponent.style.paddingTop = topPadding;
      }
    };
  }, []);

  return (
    <div className="flex h-screen w-full justify-center">
      <div className="flex flex-col justify-start items-center h-full w-full max-w-[500px]">
        {/* Contact us bar or can be used with other porpuse */}
        <div className="flex w-full justify-between bg-flux p-6">
          <img src={fluxLogo} alt="Flux Logo" className="h-8" />
          <button className="bg-white text-flux-dark px-4 rounded-full cursor-pointer">
            Contact Us
          </button>
        </div>
        {/* Main screen */}
        <div className="flex-1 p-6 w-full">
          {/* Car Data */}
          <div className="flex flex-col my-6">
            <div className="flex justify-center items-center text-black font-bold text-3xl h-20">
              <h1>{CAR_DATA.name}</h1>
            </div>
            <div className="grid grid-cols-2 gap-1 mt-2">
              <div className="text-gray-500">NAME</div>
              <div>{CAR_DATA.name}</div>
              <div className="text-gray-500">EXPIRES</div>
              <div>{CAR_DATA.expires}</div>
              <div className="text-gray-500">LOCATION</div>
              <div>
                <button 
                  className="text-flux-dark cursor-pointer"
                  onClick={() => setShowMap(prev => !prev)}
                >
                  {showMap ? 'HIDE MAP' : 'SHOW MAP'}
                </button>
              </div>
            </div>
            {showMap && (
              <div className="mt-4">
                <CarLocationMap
                  latitude={CAR_DATA.location.lat}
                  longitude={CAR_DATA.location.lng}
                  height={200}
                />
              </div>
            )}
            <div>
              
            </div>
          </div>
          {/* Controls */}
          <div className="flex flex-col space-y-4">
            <div className="flex space-x-4">
              <RoundedButton
                title="FRUNK"
                icon={arrowLeftUp}
                disabled={state !== "unlocked"}
              />
              <RoundedButton
                title="TRUNK"
                icon={arrowRightUp}
                disabled={state !== "unlocked"}
              />
            </div>
            <div>
              <RoundedButton
                title="LOCK"
                icon={locked}
                disabled={state !== "unlocked"}
              />
            </div>
            <div>
              <SwipeableButton
                state={state}
                setState={setState}
                lockedMessage="SLIDE TO UNLOCK & START"
                unlockingMessage="STARTING..."
                unlockedMessage="READY TO DRIVE"
                rightElement={
                  <CountdownTimer
                    seconds={120}
                    onComplete={handleTimerComplete}
                  />
                }
              />
            </div>
          </div>
          {/* Finished?? */}
          <div className="flex justify-center items-center text-xs mt-4">
            <p>Finished?</p>{" "}
            <button className="px-2 py-1 ml-2 border rounded cursor-pointer">
              Return Key
            </button>
          </div>
        </div>
        {/* Bottom bar */}
        <div className="bg-gray-100 w-full p-3 flex justify-center">
          <img src={fluxLogo} alt="Flux Logo" className="h-6" />
        </div>
      </div>
    </div>
  );
};

export default MockDigitalKey;
