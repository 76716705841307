import React, { useState } from 'react';
import styled from 'styled-components';
import MapComponent from '../components/map-how-to-get-to/MapComponent';

// Import step marker icons
import stepMarkerDallas from '../images/icons/step-icon.png';
import stepMarkerHouston from '../images/icons/step-icon.png';
import stepMarkerSanAntonio from '../images/icons/step-icon.png';

interface Feature {
    type: string;
    properties: {
        name: string;
        address: string;
        link: string;
        routeName: string;
        icon?: string; // Optional icon property
        isMain?: boolean; // To distinguish main from step markers
    };
    geometry: {
        type: string;
        coordinates: [number, number];
    };
}

interface MainRoute {
    name: string;
    mainFeature: Feature;       // Main marker
    stepFeature: Feature;       // Step marker
}

const mainRoutes: MainRoute[] = [
    {
        name: 'Austin to Dallas',
        mainFeature: {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [-96.7970, 32.7767],
            },
            properties: {
                name: 'Dallas',
                address: 'Dallas, TX',
                link: '#',
                routeName: 'Austin to Dallas',
                icon: '', // Default icon
                isMain: true,
            },
        },
        stepFeature: {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [-97.41014880376667, 31.079587295880128],
            },
            properties: {
                name: 'Nissan Dealership Electric Charging Station',
                address: '5420 Midway Dr, Temple, TX 76502, Estados Unidos',
                link: 'https://maps.app.goo.gl/Yno5yeEJa9WZDUMo6',
                routeName: 'Austin to Dallas',
                icon: stepMarkerDallas, // Step marker icon
                isMain: false,
            },
        },
    },
    {
        name: 'Austin to Houston',
        mainFeature: {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [-95.3698, 29.7604],
            },
            properties: {
                name: 'Houston ',
                address: 'Houston, TX',
                link: '#',
                routeName: 'Austin to Houston',
                icon: '', // Default icon
                isMain: true,
            },
        },
        stepFeature: {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [-96.50378322442867, 29.708500977650434],
            },
            properties: {
                name: 'Electrify America Charging Station',
                address: '2006 Alleyton Rd S, Columbus, TX 78934, Estados Unidos',
                link: 'https://maps.app.goo.gl/vC1WTwzkp1pHt2ua8',
                routeName: 'Austin to Houston',
                icon: stepMarkerHouston, // Step marker icon
                isMain: false,
            },
        },
    },
    {
        name: 'Austin to San Antonio',
        mainFeature: {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [-98.4936, 29.4241],
            },
            properties: {
                name: 'San Antonio ',
                address: 'San Antonio, TX',
                link: '#',
                routeName: 'Austin to San Antonio',
                icon: '', // Default icon
                isMain: true,
            },
        },
        stepFeature: {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [-97.97928214190004, 29.828005106165083],
            },
            properties: {
                name: 'EVgo Charging Station',
                address: '3939 I-35, San Marcos, TX 78666, Estados Unidos',
                link: 'https://maps.app.goo.gl/CGHNAB88Sz6mrExNA',
                routeName: 'Austin to San Antonio',
                icon: stepMarkerSanAntonio, // Step marker icon
                isMain: false,
            },
        },
    },
];

const MapHowToGetTo: React.FC = () => {
    // States
    const [selectedRoute, setSelectedRoute] = useState<MainRoute | null>(null);
    const [geoData, setGeoData] = useState<any>(
        mainRoutes.map((route) => route.mainFeature).reduce((acc: any, curr: any) => {
            acc.features.push(curr);
            return acc;
        }, { type: 'FeatureCollection', features: [] })
    );

    // Handle marker click to select a route
    const handleMarkerClick = (routeName: string) => {
        if (selectedRoute && selectedRoute.name === routeName) {
            // If the same route is clicked again, reset to show all main markers
            setSelectedRoute(null);
            setGeoData(
                mainRoutes.map((route) => route.mainFeature).reduce((acc: any, curr: any) => {
                    acc.features.push(curr);
                    return acc;
                }, { type: 'FeatureCollection', features: [] })
            );
        } else {
            // Select the new route and update geoData to include only its main and step markers
            const route = mainRoutes.find((r) => r.name === routeName);
            if (route) {
                setSelectedRoute(route);
                setGeoData({
                    type: 'FeatureCollection',
                    features: [
                        route.mainFeature,
                        route.stepFeature, // Add step marker of the selected route
                    ],
                });
            }
        }
    };

    // Center coordinates for Austin, Texas
    const initialLatitude = 30.2672;
    const initialLongitude = -97.7431;
    const initialZoom = 7;

    return (
        <Container>
            <MapWrapper>
                <MapComponent
                    initialLatitude={initialLatitude}
                    initialLongitude={initialLongitude}
                    initialZoom={initialZoom}
                    width="100%"
                    height="100%"
                    features={geoData.features}
                    onMarkerClick={handleMarkerClick}
                />
            </MapWrapper>
            {selectedRoute && (
                <BottomSheet>
                    <SheetHeader>
                        <h2>{selectedRoute.mainFeature.properties.name}</h2>
                        <CloseButton onClick={() => handleMarkerClick(selectedRoute.name)}>×</CloseButton>
                    </SheetHeader>
                    <SheetContent>
                        <p><strong>Charge required:</strong> 80%</p>
                        <h3>Recommended Stops:</h3>
                        <RecommendedStops>
                            <StopItem>
                                <StopIcon src={selectedRoute.stepFeature.properties.icon} alt="Step Marker Icon" />
                                <StopInfo>
                                    <a href={selectedRoute.stepFeature.properties.link} target="_blank" rel="noopener noreferrer">
                                        {selectedRoute.stepFeature.properties.name}
                                    </a>
                                </StopInfo>
                            </StopItem>
                            {/* If there are multiple step features, map through them here */}
                        </RecommendedStops>
                    </SheetContent>
                </BottomSheet>
            )}
        </Container>
    );
};

export default MapHowToGetTo;

// Styled Components
const Container = styled.div`
    width: 100%;
    height: calc(100vh - 77px);
    box-sizing: border-box;
    position: relative;
`;

const MapWrapper = styled.div`
    width: 100%;
    height: 100%;

    @media (max-width: 768px) {
        width: 90%;
        height: 100%;
        margin: 0 auto;
    }
`;

const BottomSheet = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: white;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    padding-bottom: env(safe-area-inset-bottom, 20px);
    max-height: 40%;
    overflow-y: auto;
    z-index: 20;
    animation: slideUp 0.3s ease-out;

    @keyframes slideUp {
        from {
            transform: translateY(100%);
        }
        to {
            transform: translateY(0);
        }
    }

    @media (max-width: 768px) {
        padding-bottom: 75px;
        max-height: 50%;
    }
`;

const SheetHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    h2 {
        margin: 0;
        font-size: 24px;
    }
`;

const CloseButton = styled.button`
    background: none;
    border: none;
    font-size: 28px;
    cursor: pointer;
    line-height: 1;
    padding: 0;
    color: #555;

    &:hover {
        color: #000;
    }
`;

const SheetContent = styled.div`
    p {
        font-size: 18px;
        margin: 10px 0;
    }

    h3 {
        margin-top: 20px;
        font-size: 20px;
    }
`;

const RecommendedStops = styled.ul`
    list-style: none;
    padding: 0;
    margin: 10px 0 0 0;
`;

const StopItem = styled.li`
    display: flex;
    align-items: center;
    margin-bottom: 15px;
`;

const StopIcon = styled.img`
    width: 40px;
    height: 40px;
    margin-right: 10px;
`;

const StopInfo = styled.div`
    a {
        text-decoration: none;
        color: #007BFF;
        font-size: 18px;

        &:hover {
            text-decoration: underline;
        }
    }
`;