import React, { useEffect } from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";

interface CarLocationMapProps {
  latitude: number;
  longitude: number;
  width?: string | number;
  height?: string | number;
}

const CarLocationMap: React.FC<CarLocationMapProps> = ({
  latitude,
  longitude,
  width = "100%",
  height = "300px",
}) => {
  const renderLoading = (status: Status): React.ReactElement => {
    switch (status) {
      case Status.LOADING:
        return <div>Loading map...</div>;
      case Status.FAILURE:
        return <div>Error loading map</div>;
      default:
        return <></>;
    }
  };

  return (
    <Wrapper
      apiKey={process.env.REACT_APP_GOOGLE_API_KEY || ""}
      render={renderLoading}
    >
      <MapComponent
        center={{ lat: latitude, lng: longitude }}
        zoom={15}
        width={width}
        height={height}
      />
    </Wrapper>
  );
};

interface MapComponentProps {
  center: google.maps.LatLngLiteral;
  zoom: number;
  width: string | number;
  height: string | number;
}

const MapComponent: React.FC<MapComponentProps> = ({
  center,
  zoom,
  width,
  height,
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [map, setMap] = React.useState<google.maps.Map>();
  const [marker, setMarker] =
    React.useState<google.maps.marker.AdvancedMarkerElement>();

  useEffect(() => {
    if (ref.current && !map) {
      const newMap = new window.google.maps.Map(ref.current, {
        center,
        zoom,
        disableDefaultUI: false,
        zoomControl: true,
        mapTypeControl: false,
        mapId: "6d5ee41397cfdef1",
      });
      setMap(newMap);
    }
  }, [ref, map, center, zoom]);

  useEffect(() => {
    if (!map) return;

    const loadMarker = async () => {
      if (marker) {
        marker.map = null;
      }

      const { AdvancedMarkerElement } = (await google.maps.importLibrary(
        "marker"
      )) as google.maps.MarkerLibrary;

      const newMarker = new AdvancedMarkerElement({
        position: center,
        map: map,
        title: "Car Location",
      });

      setMarker(newMarker);
    };

    loadMarker();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, center]);

  return <div ref={ref} style={{ width, height }} />;
};

export default CarLocationMap;
