import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
// utils
import showToast from '../utils/toastHelpers';
import { authHelpers } from '../utils/authHelpers';
// services
import fluxRestApi from '../services/FluxRestApi';

interface FormData {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
}


const SignUpFromTuro: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    // State for checkboxes
    const [marketingSms, setMarketingSms] = useState(false);
    const [accountUpdateSms, setAccountUpdateSms] = useState(false);
    const [formData, setFormData] = useState<FormData>({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: ''
    });
    const [accountExists, setAccountExists] = useState(false);
    const [phoneNumberCode, setPhoneNumberCode] = useState('');
    const [showPhoneNumberValidation, setShowPhoneNumberValidation] = useState(false);
    const [enteredCode, setEnteredCode] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isVerifying, setIsVerifying] = useState(false);
    const [key, setKey] = useState<string | null>(null);



    // Funtions

    // funtions
    const validateForm = () => {
        const { firstName, lastName, email, phoneNumber } = formData;

        if (!authHelpers.isValidPhoneNumber(phoneNumber)) {
            showToast("Invalid USA Phone Number", "error");
            return false;
        }

        if (!authHelpers.isValidEmail(email)) {
            showToast("Invalid Email!", "error");
            return false;
        }

        if (!firstName || !lastName) {
            showToast("First Name and Last Name are required!", "error");
            return false;
        }

        if (!/^[a-zA-Z\s-]+$/.test(firstName)) {
            showToast("First name contains invalid characters", "error");
            return false;
        }

        if (!/^[a-zA-Z\s-]+$/.test(lastName)) {
            showToast("Last name contains invalid characters", "error");
            return false;
        }

        return true;
    };

    const checkPhoneNumber = async (phoneNumber: string) => {
        // check the phone number is already registered
        let phone = "1" + phoneNumber
        const isPhoneNumberRegistered = await fluxRestApi.checkIfUserExistsByPhone(phone);
        if (isPhoneNumberRegistered?.data?.user_exists) throw new Error('The phone number is already registered!');
        if (isPhoneNumberRegistered === null) throw new Error('Failed to check the phone number!, please try again.');
        return true;
    };

    const checkEmail = async (email: string) => {
        try {
            const isEmailRegistered = await fluxRestApi.checkIfUserExistsByEmail(email);

            if (isEmailRegistered?.data?.user_exists) {
                throw new Error('The email is already registered!');
            }

            return true;
        } catch (error: any) {
            if (error?.response && error?.response?.status === 404) {
                // This means the email is not found, which is good in this context.
                return true;
            } else if (error?.message === 'The email is already registered!') {
                throw new Error('The email is already registered!');
            }
            throw new Error('Failed to check the email!, please try again.');
        }
    };

    const verifyPhoneNumber = async () => {
        const phone = "1" + formData.phoneNumber;
        // verify the phone number
        const data = {
            phone: phone,
            email: formData.email,
        }
        const getPhoneNumberCode = await fluxRestApi.getVerificationCode(data);
        if (getPhoneNumberCode?.data) {
            setPhoneNumberCode(getPhoneNumberCode.data);
            setShowPhoneNumberValidation(true);
            showToast("Verification code sent", "success");
        } else {
            throw new Error('Failed to send verification code!, please try again.');
        }
    };

    const handleVerifyCode = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsVerifying(true);

        if (enteredCode === phoneNumberCode) {
            createUser();
        } else {
            showToast("Invalid verification code. Please try again.", "error");
            setIsVerifying(false);
        }
    };

    const associateTuroUser = async (data: any) => {
        try{
            const response = await fluxRestApi.createAssociationTuroKey(data);
            if (response?.data?.created_at) {
                return true
            } else if (response?.data && response?.data[0] === "This user is already associated with this TuroEntry.") {
                return true
            }
            else {
                throw new Error('Failed to associate user!, please try again.');
            }
        } catch (error: any) {
            console.log("error", error);
            throw new Error('Failed to associate user!, please try again.');
        }
    };

    const associationRefactor = async() =>{
        const data = {
            "email": formData.email,
            "key": key
        }
        try {
            const association = await associateTuroUser(data);
            if (association) {
                setShowSuccess(true);
                showToast("Registration successful! Welcome to Flux.", "success");
                setIsVerifying(false);
            }
        } catch (error: any) {
            console.log("error", error);
            throw new Error('Failed to associate user!, please try again.');
        }
    };

    const createUser = async () => {
        try {
            const password = Math.random().toString(36).slice(-8);
            const phone_number = `+1${formData.phoneNumber}`
            const response = await fluxRestApi.createUser(formData.email, password, phone_number, marketingSms, accountUpdateSms, formData.firstName, formData.lastName, true, false);
            if (response?.data?.user) {
                await associationRefactor();
                return;
            } else {
                const checkEmail = await fluxRestApi.checkIfUserExistsByEmail(formData.email);
                if (checkEmail?.data?.user_exists) {
                    await associationRefactor();
                    return;
                }
                throw new Error('Failed to create user!, please try again.');
            }
        } catch (error: any) {
            showToast(error.message, "error");
            setIsVerifying(false);
            return null;
        }
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        setIsSubmitting(true);

        try {
            await checkEmail(formData.email);
            await checkPhoneNumber(formData.phoneNumber);
            await verifyPhoneNumber();
        } catch (error: any) {
            if (error.message === 'The email is already registered!') {
                const data = {
                    "email": formData.email,
                    "key": key
                }
                try {
                    const association = await associateTuroUser(data);
                    if (association) {
                        setAccountExists(true);
                        return;
                    }
                } catch (error: any) {
                    console.log("error", error);
                    showToast("Failed to associate user!");
                }
            } else {
                showToast(error.message, "error");
            }
            setIsSubmitting(false);
            return;
        }

        // Handle form submission logic here
        console.log({
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            phoneNumber: formData.phoneNumber,
            marketingSms,
            accountUpdateSms
        });

        setIsSubmitting(false);
    };

    // Effects
    useEffect(() => {
        const checkKey = async (key: string) => {
            try {
                const response = await fluxRestApi.getAssociationTuroKey(key);
                if (response?.data?.results?.length === 0) {
                    setKey(key);
                    return;
                }
                setKey(null);
            } catch (error: any) {
                console.log(error);
                setKey(null);
            }
        };

        const params = new URLSearchParams(location.search);
        const key = params.get('key') || '';
        if (!key) {
            showToast("Invalid Link", "error");
            setKey(null);
            return;
        }
        checkKey(key);
        // You can use the key parameter as needed
    }, [location]);

    // Hide the top bar
    useEffect(() => {
        let display = "";
        let topPadding = "";
        const topBar = document.getElementById('TopBar');
        const mainComponent = document.getElementById('app-routes-component');
        if (topBar && mainComponent) {
            display = topBar.style.display;
            topBar.style.display = 'none';
            topPadding = mainComponent.style.paddingTop;
            mainComponent.style.paddingTop = '0px';
        }
        // Cleanup event listener on unmount
        return () => {
            if (topBar && mainComponent) {
                topBar.style.display = display;
                mainComponent.style.paddingTop = topPadding;
            }
        };
    }, []);

    // renders
    const AccountExistsCard: React.FC<{ navigate: (path: string) => void }> = ({ navigate }) => (
        <Card>
            <LogoImage src="https://www.fluxev.city/hs-fs/hubfs/Frame.png?width=281&height=98&name=Frame.png" alt="Flux-logo" />
            <Title>Welcome Back to Flux!</Title>
            <Description>
                Thank you for being a part of Flux. It looks like you already have an account.
            </Description>
            <Buttongroup>
                <SecondaryButton onClick={() => navigate('/login')}>Log in</SecondaryButton>
                <SecondaryButton onClick={() => navigate('/reset-password')}>Reset Password</SecondaryButton>
            </Buttongroup>
            <Description>
                You can click on the button below or scan the QR code to download our Flux mobile app .
            </Description>
            <Buttongroup>
                <SecondaryButton as="a" href="http://fluxev.app.link/ScaIt0SQhAb">
                    Download
                </SecondaryButton>
            </Buttongroup>
            <img
                src="https://www.fluxev.city/hs-fs/hubfs/My%20First%20Link_QR-code.jpg?width=400&amp;height=400&amp;name=My%20First%20Link_QR-code.jpg"
                className="hs-image-widget"
                width="200"
                height="200"
                style={{ maxWidth: '100%', height: 'auto' }}
                alt="My First Link_QR-code"
                title="My First Link_QR-code"
                loading="lazy"
                srcSet="https://www.fluxev.city/hs-fs/hubfs/My%20First%20Link_QR-code.jpg?width=200&amp;height=200&amp;name=My%20First%20Link_QR-code.jpg 200w, https://www.fluxev.city/hs-fs/hubfs/My%20First%20Link_QR-code.jpg?width=400&amp;height=400&amp;name=My%20First%20Link_QR-code.jpg 400w, https://www.fluxev.city/hs-fs/hubfs/My%20First%20Link_QR-code.jpg?width=600&amp;height=600&amp;name=My%20First%20Link_QR-code.jpg 600w, https://www.fluxev.city/hs-fs/hubfs/My%20First%20Link_QR-code.jpg?width=800&amp;height=800&amp;name=My%20First%20Link_QR-code.jpg 800w, https://www.fluxev.city/hs-fs/hubfs/My%20First%20Link_QR-code.jpg?width=1000&amp;height=1000&amp;name=My%20First%20Link_QR-code.jpg 1000w, https://www.fluxev.city/hs-fs/hubfs/My%20First%20Link_QR-code.jpg?width=1200&amp;height=1200&amp;name=My%20First%20Link_QR-code.jpg 1200w"
                sizes="(max-width: 200px) 100vw, 200px"
            />
        </Card>
    );

    const VerificationCard: React.FC<{
        enteredCode: string;
        setEnteredCode: React.Dispatch<React.SetStateAction<string>>;
        handleVerifyCode: (e: React.FormEvent<HTMLFormElement>) => void;
    }> = ({ enteredCode, setEnteredCode, handleVerifyCode }) => (
        <Card>
            <LogoImage src="https://www.fluxev.city/hs-fs/hubfs/Frame.png?width=281&height=98&name=Frame.png" alt="Flux-logo" />
            <Title>Verify Your Phone Number</Title>
            <Description>
                Please enter the verification code sent to your phone number.
            </Description>
            <Form onSubmit={handleVerifyCode}>
                <FormGroup>
                    <Label htmlFor="verificationCode">Verification Code:</Label>
                    <Input
                        type="text"
                        id="verificationCode"
                        name="verificationCode"
                        value={enteredCode}
                        onChange={(e) => setEnteredCode(e.target.value)}
                        required
                        placeholder="Enter Code"
                    />
                </FormGroup>
                <Button type="submit" disabled={isVerifying}>
                    {isVerifying ? (
                        <>
                            <Spinner />
                        </>
                    ) : (
                        'Verify'
                    )}
                </Button>
            </Form>
        </Card>
    );

    const EmailConfirmation: React.FC<{ navigate: (path: string) => void }> = ({ navigate }) => (
        <Card>
            <LogoImage src="https://www.fluxev.city/hs-fs/hubfs/Frame.png?width=281&height=98&name=Frame.png" alt="Flux-logo" />
            <Title>Registration Successful!</Title>
            <Description>
                Please check your email to confirm your account and create a password. Once confirmed, you can log in to your account.
            </Description>
            <Buttongroup>
                <SecondaryButton onClick={() => navigate('/login')}>Log in</SecondaryButton>
            </Buttongroup>
            <Description>
                You can click on the button below or scan the QR code to download our Flux mobile app .
            </Description>
            <Buttongroup>
                <SecondaryButton as="a" href="http://fluxev.app.link/ScaIt0SQhAb">
                    Download
                </SecondaryButton>
            </Buttongroup>
            <img
                src="https://www.fluxev.city/hs-fs/hubfs/My%20First%20Link_QR-code.jpg?width=400&amp;height=400&amp;name=My%20First%20Link_QR-code.jpg"
                className="hs-image-widget"
                width="200"
                height="200"
                style={{ maxWidth: '100%', height: 'auto' }}
                alt="My First Link_QR-code"
                title="My First Link_QR-code"
                loading="lazy"
                srcSet="https://www.fluxev.city/hs-fs/hubfs/My%20First%20Link_QR-code.jpg?width=200&amp;height=200&amp;name=My%20First%20Link_QR-code.jpg 200w, https://www.fluxev.city/hs-fs/hubfs/My%20First%20Link_QR-code.jpg?width=400&amp;height=400&amp;name=My%20First%20Link_QR-code.jpg 400w, https://www.fluxev.city/hs-fs/hubfs/My%20First%20Link_QR-code.jpg?width=600&amp;height=600&amp;name=My%20First%20Link_QR-code.jpg 600w, https://www.fluxev.city/hs-fs/hubfs/My%20First%20Link_QR-code.jpg?width=800&amp;height=800&amp;name=My%20First%20Link_QR-code.jpg 800w, https://www.fluxev.city/hs-fs/hubfs/My%20First%20Link_QR-code.jpg?width=1000&amp;height=1000&amp;name=My%20First%20Link_QR-code.jpg 1000w, https://www.fluxev.city/hs-fs/hubfs/My%20First%20Link_QR-code.jpg?width=1200&amp;height=1200&amp;name=My%20First%20Link_QR-code.jpg 1200w"
                sizes="(max-width: 200px) 100vw, 200px"
            />
        </Card>
    );

    // if key is not present return invalid link
    if (!key) {
        return (
            <Container>
            {/* Background Video */}
            <BackgroundVideo autoPlay muted loop>
                <source src="https://23820752.fs1.hubspotusercontent-na1.net/hubfs/23820752/kickballpro%2003.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </BackgroundVideo>
            <Overlay />
            <Wrapper>
                <Card>
                    <LogoImage src="https://www.fluxev.city/hs-fs/hubfs/Frame.png?width=281&height=98&name=Frame.png" alt="Flux-logo" />
                    <Title>Invalid Link</Title>
                    <Description>
                        The link is invalid. Please reach out to Flux support for assistance.
                    </Description>
                </Card>
            </Wrapper>
        </Container>
        );
    }

    return (
        <Container>
            {/* Background Video */}
            <BackgroundVideo autoPlay muted loop>
                <source src="https://23820752.fs1.hubspotusercontent-na1.net/hubfs/23820752/kickballpro%2003.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </BackgroundVideo>

            <Overlay />
            <Wrapper>
                {accountExists ? (
                    <AccountExistsCard navigate={navigate} />
                ) : showSuccess ? (
                    <EmailConfirmation navigate={navigate} />
                ) : showPhoneNumberValidation ? (
                    <VerificationCard
                        enteredCode={enteredCode}
                        setEnteredCode={setEnteredCode}
                        handleVerifyCode={handleVerifyCode}
                    />
                ) : (
                    <Card>
                        <LogoImage src="https://www.fluxev.city/hs-fs/hubfs/Frame.png?width=281&height=98&name=Frame.png" alt="Flux-logo" />
                        <Title>Welcome to Flux</Title>
                        <Description>
                            Welcome to Flux! Enjoy fantastic deals on electric vehicle rentals. Simply fill in the form to sign up and make your next Flux rental a wonderful experience.
                        </Description>

                        <Form onSubmit={handleSubmit}>
                            <FormGroup>
                                <Label htmlFor="firstName">First Name:</Label>
                                <Input
                                    type="text"
                                    id="firstName"
                                    name="firstName"
                                    value={formData.firstName}
                                    onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="lastName">Last Name:</Label>
                                <Input
                                    type="text"
                                    id="lastName"
                                    name="lastName"
                                    value={formData.lastName}
                                    onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="email">Email:</Label>
                                <Input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="phone">Phone Number:</Label>
                                <PhoneInputContainer>
                                    <CountryCode>
                                        <Flag role="img" aria-label="US Flag">🇺🇸</Flag> +1
                                    </CountryCode>
                                    <PhoneNumberInput
                                        type="tel"
                                        id="phone"
                                        name="phone"
                                        value={formData.phoneNumber}
                                        onChange={(e) => setFormData({ ...formData, phoneNumber: e.target.value })}
                                        required
                                        placeholder="5551234567"
                                        pattern="^\d{10}$"
                                        title="Enter a valid 10-digit US phone number (e.g., 5551234567)"
                                    />
                                </PhoneInputContainer>
                            </FormGroup>

                            {/* Checkboxes */}
                            <FormGroup>
                                <CheckboxLabel htmlFor="marketingSms">
                                    <CheckboxInput
                                        type="checkbox"
                                        id="marketingSms"
                                        name="marketingSms"
                                        checked={marketingSms}
                                        onChange={(e) => setMarketingSms(e.target.checked)}
                                    />
                                    I agree to receive marketing SMS messages from Flux.
                                </CheckboxLabel>
                            </FormGroup>
                            <FormGroup>
                                <CheckboxLabel htmlFor="accountUpdateSms">
                                    <CheckboxInput
                                        type="checkbox"
                                        id="accountUpdateSms"
                                        name="accountUpdateSms"
                                        checked={accountUpdateSms}
                                        onChange={(e) => setAccountUpdateSms(e.target.checked)}
                                    />
                                    I agree to receive account update SMS messages from Flux.
                                </CheckboxLabel>
                            </FormGroup>

                            {/* Consent Text */}
                            <ConsentText>
                                By selecting the checkboxes above, you agree to receive text messages from Flux to the phone number provided. Message and data rates may apply. Message frequency varies. Text HELP for help, Text STOP to stop.
                                <br /><br />
                                By signing up, I agree to Flux's <a href="https://www.fluxev.city/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and <a href="https://www.fluxev.city/tos" target="_blank" rel="noopener noreferrer">Terms of Service</a>.
                            </ConsentText>

                            <Button type="submit" disabled={isSubmitting}>
                                {isSubmitting ? (
                                    <>
                                        <Spinner />
                                    </>
                                ) : (
                                    'Submit'
                                )}
                            </Button>
                        </Form>
                    </Card>
                )}
            </Wrapper>
        </Container>
    );
};

export default SignUpFromTuro;

// Styled Components
const Spinner = styled.div`
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #000000;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin 1s linear infinite;
    margin: 0 auto; /* Center the spinner horizontally */

    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
`;

const Buttongroup = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
`;


const SecondaryButton = styled.button`
    background-color: #b2d700;
    color: #000000;
    padding: 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    transition: background-color 0.3s ease;
    margin: 10px;
    width: 200px;
    text-decoration: none; /* Remove underline for anchor tag */

    &:hover {
        background-color: #a0c900;
    }

    &:link, &:visited {
        text-decoration: none; /* Ensure underline is removed for links */
    }

    @media (max-width: 480px) {
        padding: 10px;
        font-size: 12px;
    }
`;


const BackgroundVideo = styled.video`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -2;
`;

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(26, 36, 15, 0.7); /* Semi-transparent overlay for readability */
    z-index: -1;
`;

const PhoneInputContainer = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid #ccc; /* Single border for the entire phone input */
    border-radius: 4px;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
    background-color: #fff; /* Ensure background is white for consistency */

    &:focus-within {
        border-color: #28a745;
    }
`;

const CountryCode = styled.span`
    background-color: #e9ecef;
    padding: 10px 15px;
    font-size: 16px;
    color: #333;
    display: flex;
    align-items: center;
    border-right: 1px solid #ccc; /* Add a subtle separator */
    flex-shrink: 0; /* Prevent the country code section from shrinking */
`;

const Flag = styled.span`
    margin-right: 5px;
    font-size: 16px;
`;

// Rename the phone input styled component to avoid conflict
const PhoneNumberInput = styled.input`
    padding: 12px;
    font-size: 16px;
    border: none; /* Remove border to prevent double borders */
    flex: 1; /* Allow the input to take up the remaining space */
    outline: none;
    color: #333;
    background-color: #fff; /* Ensure background matches the container */

    transition: border-color 0.3s ease;

    &:focus {
        /* Optional */
    }

    @media (max-width: 480px) {
        padding: 10px;
        font-size: 14px;
    }
`;

const Container = styled.div`
    position: relative;
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: flex-start; /* Align to the top to respect top padding */
    height: 100vh; /* Full viewport height */
    padding: 0 20px; /* Horizontal padding */
    box-sizing: border-box;
    overflow-y: auto; /* Enable vertical scrolling if needed */
    /* Removed background-color since we have a video background */
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 5px; /* Space at the top */
    padding-bottom: 200px; /* Space at the bottom */
    box-sizing: border-box;
    position: relative;
    z-index: 1; /* Ensure content is above the overlay */
`;

const Card = styled.div`
    background-color: rgba(255, 255, 255, 0.9); /* Slight transparency for better integration with the background */
    padding: 40px 30px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    text-align: center;
    box-sizing: border-box;

    @media (max-width: 480px) {
        padding: 30px 20px;
    }
`;

const LogoImage = styled.img`
    width: 120px;
    height: auto;
    margin-bottom: 20px;
`;

const Title = styled.h1`
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;

    @media (max-width: 480px) {
        font-size: 20px;
    }
`;

const Description = styled.p`
    font-size: 16px;
    margin-bottom: 30px;
    color: #555;
    line-height: 1.5;

    @media (max-width: 480px) {
        font-size: 14px;
    }
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
`;

const FormGroup = styled.div`
    margin-bottom: 15px;
`;

const Label = styled.label`
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
    display: block;
    color: #333;
`;

const Input = styled.input`
    padding: 12px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #ccc;
    width: 100%;
    box-sizing: border-box;
    outline: none;
    transition: border-color 0.3s ease;

    &:focus {
        border-color: #28a745;
    }

    @media (max-width: 480px) {
        padding: 10px;
        font-size: 14px;
    }
`;

const Button = styled.button`
    background-color: #C7F100;
    color: #000000;
    padding: 14px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s ease;
    margin-top: 10px;

    &:hover {
        background-color: #b2d700;
    }

    @media (max-width: 480px) {
        padding: 12px;
        font-size: 14px;
    }
`;

// Additional Styled Components for Checkboxes and Consent Text

const CheckboxLabel = styled.label`
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #333;
    cursor: pointer;
`;

const CheckboxInput = styled.input`
    margin-right: 10px;
    width: 16px;
    height: 16px;
`;

const ConsentText = styled.p`
    font-size: 12px;
    color: #555;
    margin-top: 10px;
    text-align: left;

    a {
        color: #28a745;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    @media (max-width: 480px) {
        font-size: 10px;
    }
`;

