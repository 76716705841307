// RentalAdapter.tsx
import React, { useState, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from '../../components/adapters/CheckoutForm';
import fluxRestApi from '../../services/FluxRestApi';

import CryptoJS from 'crypto-js';

interface FormData {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    marketingSms: boolean;
    accountUpdateSms: boolean;
}

interface AdapterInfo {
    name: string;
    description: string;
    price: number; // Price in cents
}

const secretKey = process.env.REACT_APP_ENCRYPTION_KEY || '';

// Get the token from the URL query parameters
const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const stripe_key = process.env.REACT_APP_STRIPE_PUBLISH ? process.env.REACT_APP_STRIPE_PUBLISH : '';
const stripePromise = loadStripe(stripe_key);

const RentalAdapter: React.FC = () => {
    const [selectedAdapter, setSelectedAdapter] = useState<string>("");
    const [formData, setFormData] = useState<FormData>({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        marketingSms: false,
        accountUpdateSms: false
    });

    // Code used to generate the encrypted token
    // if (secretKey && secretKey !== '') {
    //     const adapterToken = CryptoJS.AES.encrypt('adapter2', secretKey).toString();
    //     console.log(adapterToken);
    // }

    // Params
    const query = useQuery();
    const token = query.get('token');

    const [clientSecret, setClientSecret] = useState<string | null>(null);
    const [isLoadingPayment, setIsLoadingPayment] = useState<boolean>(false);
    const [paymentError, setPaymentError] = useState<string | null>(null);

    // funtions

    const adapterDetails: { [key: string]: AdapterInfo } = {
        adapter1: {
            name: 'Tesla Supercharger Adapter',
            description:
                'Tesla Supercharger stations use the North American Charging Standard (NACS) electric vehicle connector. The native plug protocol for GM EVs is the Combined Charging System (CCS), which is widely shared across non-Tesla EVs and charging stations. Until a NACS port becomes native to GM EVs, which will likely begin with the 2026 model year, charging a GM EV at a Tesla Supercharger station requires a NACS adapter.',
            price: 2500, // $25.00
        },
        adapter2: {
            name: 'ChargeMaster',
            description:
                'ChargeMaster [Universal EV Charging Adapter, Supports J1772 and CCS Connectors, Max 40 Amp & 220V - Ideal for Various EV Models and Charging Stations (Silver)]',
            price: 2500, // $25.00
        }
    };

    // Decode token function
    const decodeToken = useCallback((token: any, secretKey: any) => {
        try {
            const bytes = CryptoJS.AES.decrypt(token, secretKey);
            const decryptedData = bytes.toString(CryptoJS.enc.Utf8);

            if (!decryptedData.startsWith("adapter")) {
                console.error('Token is missing adapter data');
                return null;
            }

            return decryptedData;
        } catch (error) {
            console.error('Error decoding token:', error);
            return null;
        }
    }, []);


    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: checked
        }));
    }

    const createPaymentIntent = async (contactData: any) => {
        if (!selectedAdapter) {
            console.error('No adapter selected');
            setPaymentError('Please select an adapter to proceed.');
            return;
        }
        if (!contactData?.email || !contactData?.stripe_id) {
            console.error('No contact data');
            setPaymentError('Failed to get contact data. Please try again.');
            return;
        }

        setIsLoadingPayment(true);
        setPaymentError(null);
        try {
            const amount = adapterDetails[selectedAdapter].price;
            const currency = 'usd'; // You can make this dynamic if needed

            const response = await fluxRestApi.createPaymentIntentForRentalAdapter({
                "amount": amount,
                "currency": currency,
                "email": contactData.email,
                "customerId": contactData.stripe_id,
                "selectedAdapter": selectedAdapter
            });

            if (!response.client_secret) {
                if (response?.response.data?.error){
                    if (response?.response.data?.error?.includes('a similar object exists in live mode')) {
                        throw new Error('This customer is set for live mode');
                    }
                    throw new Error(response.response.data.error);
                }
                throw new Error('Invalid payment intent response');
            }
            setClientSecret(response.client_secret);
        } catch (error: any) {
            // console.error('Error creating payment intent:', error);
            const errorMessage = error?.response?.data?.error || error.message;
            setPaymentError('Error: ' + errorMessage);
        } finally {
            setIsLoadingPayment(false);
        }
    };

    const checkUserExistByEmail = async (email: string) => {
        try {
            const response = await fluxRestApi.checkIfUserExistsByEmail(email);
            if (response?.data?.user_exists) {
                return true;
            }
            throw new Error('The email is already registered!');
        } catch (error: any) {
            console.error('Error checking user by email:', error);
            return false;
        }
    };

    const checkPhoneNumber = async (phoneNumber: string) => {
        // check the phone number is already registered
        let phone = "1" + phoneNumber
        const isPhoneNumberRegistered = await fluxRestApi.checkIfUserExistsByPhone(phone);
        let error = ''
        if (isPhoneNumberRegistered?.data?.user_exists) {
            error = 'The phone number is already registered for another user!';
            return error;
        }
        if (isPhoneNumberRegistered === null) {
            error = 'Failed to check the phone number. Please try again.'
            return error;
        }
        return error;
    };

    const getContactDataByEmail = async (email: string) => {
        try {
            const response = await fluxRestApi.getContactDataByEmail(email);
            if (!response?.data) {
                throw new Error('No contact found with this email!');
            }
            return response.data;
        } catch (error: any) {
            console.error('Error getting contact by email:', error);
            return null;
        }
    }

    const getOrCreateFluxUser = async () => {
        setIsLoadingPayment(true);
        setPaymentError(null);
        try {
            const userExists = await checkUserExistByEmail(formData.email);
            if (!userExists) {
                // check the phone number is already registered for another user
                const phoneNumberError = await checkPhoneNumber(formData.phoneNumber);
                if (phoneNumberError) {
                    throw new Error(phoneNumberError);
                }
                const password = Math.random().toString(36).slice(-8);
                const phone_number = `+1${formData.phoneNumber}`;
                const response = await fluxRestApi.createUser(formData.email, password, phone_number, formData.marketingSms, formData.accountUpdateSms, formData.firstName, formData.lastName, true, false);
                if (response?.data?.flux_properties) {
                    return response.data.flux_properties;
                }
                throw new Error('Failed to create user!');
            } else {
                const contactData = await getContactDataByEmail(formData.email);
                if (!contactData) {
                    throw new Error('No contact found with this email!');
                }
                return contactData.contact
            }
        } catch (error: any) {
            // console.error('Error', error);
            setPaymentError('Error: ' + error.message);
        } finally {
            setIsLoadingPayment(false);
        }
    };

    const handleRentalDetailsSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        // Handle form submission logic (e.g., save rental details)
        const contactData = await getOrCreateFluxUser();
        // console.log('contactData', contactData);
        // Proceed to payment
        if (contactData) {
            await createPaymentIntent(contactData);
        } else {
            console.log('User does not exist');
        }
    };
    // Decode token and set the adapter
    useEffect(() => {
        if(token) {
            const decodedToken = decodeToken(token, secretKey);
            if (decodedToken) {
                setSelectedAdapter(decodedToken);
            }
        }
    }, [token, decodeToken]);

    const currentAdapterInfo = adapterDetails[selectedAdapter];

    // effects
    // Hide the top bar
    useEffect(() => {
        let display = "";
        let topPadding = "";
        const topBar = document.getElementById('TopBar');
        const mainComponent = document.getElementById('app-routes-component');
        if (topBar && mainComponent) {
            display = topBar.style.display;
            topBar.style.display = 'none';
            topPadding = mainComponent.style.paddingTop;
            mainComponent.style.paddingTop = '0px';
        }
        // Cleanup event listener on unmount
        return () => {
            if (topBar && mainComponent) {
                topBar.style.display = display;
                mainComponent.style.paddingTop = topPadding;
            }
        };
    }, []);

    // renders

    if (clientSecret) {
        const options = { clientSecret };
        return (
            <Elements stripe={stripePromise} options={options}>
                <Container>
                    <Wrapper>
                        <Card>
                            <Title>Payment Details</Title>
                            <PaymentDetailsCard>
                                <CheckoutForm />
                            </PaymentDetailsCard>
                        </Card>
                    </Wrapper>
                </Container>
            </Elements>
        );
    }

    return (
        <Container>
            <Wrapper>
                {/* Rental Details Card */}
                {selectedAdapter ? (
                    
                <Card>
                    <Title>Rental Details</Title>
                    <AdapterInfoSection>
                        <AdapterName>{currentAdapterInfo.name}</AdapterName>
                        <AdapterDescription>{currentAdapterInfo.description}</AdapterDescription>
                        <Price>${(currentAdapterInfo.price / 100).toFixed(2)}</Price>
                    </AdapterInfoSection>
                    <Form onSubmit={handleRentalDetailsSubmit}>
                        <FormGroup>
                            <Label htmlFor="firstName">First Name:</Label>
                            <Input
                                type="text"
                                id="firstName"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleInputChange}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="lastName">Last Name:</Label>
                            <Input
                                type="text"
                                id="lastName"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleInputChange}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="email">Email:</Label>
                            <Input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="phoneNumber">Phone Number:</Label>
                            <Input
                                type="tel"
                                id="phoneNumber"
                                name="phoneNumber"
                                value={formData.phoneNumber}
                                onChange={handleInputChange}
                                required
                                placeholder="5551234567"
                                pattern="^\d{10}$"
                                title="Enter a valid 10-digit US phone number (e.g., 5551234567)"
                            />
                        </FormGroup>
                        {/* Checkboxes */}
                        <FormGroup>
                            <CheckboxLabel htmlFor="marketingSms">
                                <CheckboxInput
                                    type="checkbox"
                                    id="marketingSms"
                                    name="marketingSms"
                                    checked={formData.marketingSms}
                                    onChange={handleCheckboxChange}
                                />
                                I agree to receive marketing SMS messages from Flux.
                            </CheckboxLabel>
                        </FormGroup>
                        <FormGroup>
                            <CheckboxLabel htmlFor="accountUpdateSms">
                                <CheckboxInput
                                    type="checkbox"
                                    id="accountUpdateSms"
                                    name="accountUpdateSms"
                                    checked={formData.accountUpdateSms}
                                    onChange={handleCheckboxChange}
                                />
                                I agree to receive account update SMS messages from Flux.
                            </CheckboxLabel>
                        </FormGroup>
                        <Button type="submit" disabled={isLoadingPayment}>
                            {isLoadingPayment ? 'Processing...' : 'Proceed to Payment'}
                        </Button>
                        {paymentError && <ErrorText>{paymentError}</ErrorText>}
                    </Form>
                </Card>
                ) : (
                    <Card>
                        <Title>Adapter Not Found</Title>
                        <p>The adapter you are looking for was not found.</p>
                    </Card>
                )}
            </Wrapper>
        </Container>
    );
};

export default RentalAdapter;

// Styled Components
const CheckboxLabel = styled.label`
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #333;
    cursor: pointer;
`;

const CheckboxInput = styled.input`
    margin-right: 10px;
    width: 16px;
    height: 16px;
`;

const Container = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100vh;
    padding: 0 20px;
    box-sizing: border-box;
    overflow-y: auto;
    background-color: #f9f9f9;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 200px;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
`;

const Card = styled.div`
    margin-bottom: 20px;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 40px 30px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    text-align: center;
    box-sizing: border-box;

    @media (max-width: 480px) {
        padding: 30px 20px;
    }
`;

const Title = styled.h1`
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;

    @media (max-width: 480px) {
        font-size: 20px;
    }
`;

const AdapterInfoSection = styled.div`
    text-align: left;
    margin-top: 10px;
`;

const AdapterName = styled.h2`
    font-size: 18px;
    font-weight: bold;
    color: #555;
    margin-bottom: 8px;

    @media (max-width: 480px) {
        font-size: 16px;
    }
`;

const AdapterDescription = styled.p`
    font-size: 14px;
    color: #666;
    line-height: 1.5;

    @media (max-width: 480px) {
        font-size: 13px;
    }
`;

const Price = styled.p`
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin-top: 10px;

    @media (max-width: 480px) {
        font-size: 14px;
    }
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
`;

const FormGroup = styled.div`
    margin-bottom: 15px;
`;

const Label = styled.label`
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
    display: block;
    color: #333;
`;

const Input = styled.input`
    padding: 12px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #ccc;
    width: 100%;
    box-sizing: border-box;
    outline: none;
    transition: border-color 0.3s ease;

    &:focus {
        border-color: #28a745;
    }

    @media (max-width: 480px) {
        padding: 10px;
        font-size: 14px;
    }
`;

const Button = styled.button`
    background-color: #C7F100;
    color: #000000;
    padding: 14px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s ease;
    margin-top: 10px;

    &:hover {
        background-color: #b2d700;
    }

    &:disabled {
        background-color: #e3e3e3;
        cursor: not-allowed;
    }

    @media (max-width: 480px) {
        padding: 12px;
        font-size: 14px;
    }
`;

const ErrorText = styled.p`
    color: red;
    margin-top: 10px;
    font-size: 14px;
`;

const PaymentDetailsCard = styled.div`
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
`;
